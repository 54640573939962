import { Getter } from 'vuex'
import { UserGetters, UserState } from '../types'
import { RootState } from '@/store/types'
import { ProjectID } from '@/types/platform'

const hasUserAccessToProject: Getter<UserState, RootState> = (
  state,
  getters,
  rootState
) => {
  const getProjectAccessUserRoles = getters[
    'getProjectAccessUserRoles'
  ] as UserGetters['getProjectAccessUserRoles']

  const getter: UserGetters['hasUserAccessToProject'] = ({
    projectId = ProjectID[rootState.platform],
    roles,
  }) => {
    return getProjectAccessUserRoles(projectId).some((projectAccessUserRole) =>
      roles.includes(projectAccessUserRole)
    )
  }

  return getter
}

export default hasUserAccessToProject
