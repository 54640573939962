import axios, { CancelToken } from 'axios'

export default async function fetchArticleStatTotalViews({
  articleId,
  cancelToken,
  projectId,
}: {
  articleId: number
  cancelToken?: CancelToken
  projectId?: number
}): Promise<number> {
  const headers: Record<string, string | number> = {}

  if (projectId) {
    headers['X-Project-ID'] = projectId
  }
  const { data } = await axios.get(
    `/api/v1/statistic/article/total-views/${articleId}`,
    {
      cancelToken,
      headers,
    }
  )

  return data
}
