import { ActionListPayload, ActionListResult } from '@/types/action'
import { Expert } from '@/types/expert'
import axios from 'axios'

export default async function fetchExperts({
  withItem,
  cancelToken,
  ...params
}: ActionListPayload): Promise<ActionListResult<Expert>> {
  const { data } = await axios.get('/api/v1/expert/', {
    cancelToken,
    params: {
      ...params,
      with: withItem,
    },
  })
  return data
}
