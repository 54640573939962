import { ActionListPayload, ActionListResult } from '@/types/action'
import { Fact } from '@/types/fact'
import axios from 'axios'

export default async function fetchFacts({
  filter,
  withItem,
  count,
  sort,
  page,
  cancelToken,
}: ActionListPayload): Promise<ActionListResult<Fact>> {
  const { data } = await axios.get('/api/v1/fact', {
    cancelToken,
    params: {
      page,
      sort,
      count,
      filter,
      with: withItem,
    },
  })
  return data
}
