import { ActionItemResult } from '@/types/action'
import { Popup } from '@/types/popup'
import axios from 'axios'

export default async function createPopup(
  payload: Partial<Omit<Popup, 'id' | 'extra_fields'>> & {
    extra_fields: Partial<Popup['extra_fields']>
  }
): Promise<ActionItemResult<Popup>> {
  const { data } = await axios.post('/api/v1/popup/', payload)
  return data
}
