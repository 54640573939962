import { APP_FRONT_ENV } from '@/config/base'
import { Platform, PlatformOrigin } from '@/types/platform'

export const computePlatformOrigin = (platform: Platform) => {
  const origin = PlatformOrigin[platform]

  if (APP_FRONT_ENV === 'PROD') {
    return origin
  }

  return origin
    .replace('www.', '')
    .replace('://', `://${APP_FRONT_ENV.toLowerCase()}.`)
}
