import axios from 'axios'

export default async function updateFixedArticle({
  id,
  ...payload
}: {
  id: number
  fixed_text: string
}) {
  const { data } = await axios.put(`/api/v1/fixed/article/${id}`, payload)

  return data
}
