import { ActionListPayload, ActionListResult } from '@/types/action'
import { RecipeProduct } from '@/types/recipe'
import axios from 'axios'

export default async function fetchRecipeProducts({
  cancelToken,
  withItem,
  ...params
}: ActionListPayload): Promise<ActionListResult<RecipeProduct>> {
  const { data } = await axios.get('/api/v1/recipe-product/', {
    cancelToken,
    params: {
      ...params,
      with: withItem,
    },
  })
  return data
}
