import axios from 'axios'
import { ActionListPayload, ActionListResult } from '@/types/action'
import { ContactPerson } from '@/types/contact'

export default async function fetchContactsPersons({
  page,
  count,
}: ActionListPayload): Promise<ActionListResult<ContactPerson>> {
  const {
    data: { items },
  } = await axios.get('/api/v1/contacts-person/', {
    params: { page, count },
  })

  return items
}
