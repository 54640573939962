import { ActionTree } from 'vuex'
import { AuthState } from '../types'
import { RootState } from '@/store/types'
import logOut from './logOut'

const actions: ActionTree<AuthState, RootState> = {
  logOut,
}

export default actions
