export const modelUpdatedEvent = 'ModelUpdated'
export const modelUpdatedSyncEvent = 'ModelUpdatedSync'
export const modelBlockedEvent = 'ModelBlocked'
export const richHtmlBlockBlockedEvent = 'RichHtmlBlockBlocked'
export const richHtmlBlockBlockedSyncEvent = 'RichHtmlBlockBlockedSync'
export const richHtmlBlockUpdatedEvent = 'RichHtmlBlockUpdated'
export const richHtmlBlockUpdatedSyncEvent = 'RichHtmlBlockUpdatedSync'
export const richHtmlBlockCreatedEvent = 'RichHtmlBlockCreated'
export const stopWordNoticeEvent = 'StopWordNotice'

// При возникновении ошибки во время работы любого экшена очереди, генерится событие QueueActionFailed в сокеты.
export const queueActionFailedEvent = 'QueueActionFailed'
export const asyncResponseEvent = 'AsyncResponse'
