import { ActionTree } from 'vuex'
import { UserState } from '../types'
import { RootState } from '@/store/types'

import fetchProfile from './fetchProfile'

const actions: ActionTree<UserState, RootState> = {
  fetchProfile,
}

export default actions
