import axios from 'axios'
import { ActionListPayload, ActionListResult } from '@/types/action'
import { Author } from '@/types/author'

export default async function fetchAuthors(
  params: ActionListPayload
): Promise<ActionListResult<Author>> {
  const { data } = await axios.get(`/api/v1/author/dic/`, {
    params,
  })
  return data
}
