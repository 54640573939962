import { Dish } from '@/types/recipe'
import axios from 'axios'

export default async function updateRecipeDish({
  id,
  ...payload
}: Pick<Dish, 'id'> & Partial<Omit<Dish, 'id'>>) {
  const { data } = await axios.put(`/api/v1/recipe-dish/${id}`, payload)

  return data
}
