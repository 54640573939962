import axios from 'axios'
import { DetailEpisode } from '@/types/episode'

export default async function uploadAudio({
  id,
  file,
}: {
  id: number
  file: FormData
}): Promise<{ item: DetailEpisode }> {
  const { data } = await axios.post<{ item: DetailEpisode }>(
    `/api/v1/podcast-episode/upload/${id}`,
    file
  )

  return data
}
