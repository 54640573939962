import { PeriodCountData } from '@/types/statistics'
import { ActionListPayload } from '@/types/action'
import axios from 'axios'

export default async function fetchStatisticViewsContent(
  params: ActionListPayload & {
    type?: string
    publish_date?: string | string[]
    views_date?: string | string[]
    page?: number
    count?: number
  }
): Promise<PeriodCountData> {
  const data = await axios.get(
    '/api/v1/statistic/clickhouse/by-content-type/views',
    {
      params,
    }
  )

  return data.data
}
