import axios from 'axios'

export default async function deleteImageMediaLibrary({
  essenceId,
  essence,
  image_id,
}: {
  image_id: number
  essence: string
  essenceId: number
}) {
  const { data } = await axios.delete(
    `/api/v1/${essence}-media-library/${image_id}/`,
    {
      params: { [`${essence}_id`]: essenceId },
    }
  )

  return data
}
