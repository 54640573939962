import { ActionItemResult } from '@/types/action'
import { Image } from '@/types/image'
import axios from 'axios'

export default async function addImageByUrl({
  url,
}: {
  url: string
}): Promise<ActionItemResult<Image>> {
  const { data } = await axios.post(
    '/api/v1/image/url',
    { url },
    {
      useRetry: false,
    }
  )

  return data
}
