import { ActionListPayload, ActionListResult } from '@/types/action'
import { Article } from '@/types/article'
import axios from 'axios'

export default async function fetchArticlePushToday({
  withItem,
  page,
  count,
  cancelToken,
  filter,
  sort,
  select,
}: ActionListPayload): Promise<ActionListResult<Article>> {
  const { data } = await axios.get('/api/v1/article/push-today/', {
    cancelToken,
    params: {
      page,
      count,
      filter,
      sort,
      select,
      with: withItem,
    },
  })

  return data
}
