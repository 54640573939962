import { MutationTree } from 'vuex'
import { SocialDistributionState } from '../types'
import setGroups from './setGroups'
import setSocialDistribution from './setSocialDistribution'
import setSocialDistributionLoading from './setSocialDistributionLoading'
import setSocialDistributionItems from './setSocialDistributionItems'
import updateSocialDistribution from './updateSocialDistribution'
import resetOptions from './resetOptions'
import setOptions from './setOptions'
import setSocialDistributionPublished from './setSocialDistributionPublished'

const mutations: MutationTree<SocialDistributionState> = {
  setGroups,
  setSocialDistribution,
  setSocialDistributionLoading,
  setSocialDistributionItems,
  updateSocialDistribution,
  resetOptions,
  setOptions,
  setSocialDistributionPublished,
}

export default mutations
