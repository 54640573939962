import axios from 'axios'

export default async function unPublishArticle(id: number) {
  return axios.post(`/api/v1/article/unpublish/${id}`, null, {
    useRetry: false,
    useWS: {
      model: 'article',
      itemId: id,
    },
  })
}
