export default async function checkOnline(): Promise<boolean> {
  return await new Promise((resolve) => {
    const img = new Image()

    img.src = `/favicon.ico?t=${Date.now()}`

    img.addEventListener('load', () => {
      resolve(true)
    })

    img.addEventListener('error', () => {
      resolve(false)
    })
  })
}
