import { Question, QuestionCreated } from '@/types/test'
import axios from 'axios'

export default async function editTestQuestion({
  id,
  item,
}: {
  id: number
  item: Question | QuestionCreated
}) {
  await axios.put(`/api/v1/test-question/${id}`, { ...item })
}
