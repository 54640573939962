import { Getter } from 'vuex'
import { RootState } from '@/store/types'
import { SocialDistributionState } from '../types'
import { socials } from '@/config/socialDistribution'

const availableSocials: Getter<SocialDistributionState, RootState> = (
  state
) => {
  return Object.keys(state.groups).map((socialCode) => {
    const code = socialCode as keyof typeof socials
    const social = socials[code]

    return {
      text: social.title || code,
      value: socialCode,
    }
  })
}

export default availableSocials
