import { ActionListPayload, ActionListResult, Field } from '@/types/action'
import { Popup } from '@/types/popup'
import axios from 'axios'

export default async function fetchPopups({
  filter,
  withItem,
  count,
  sort,
  page,
  cancelToken,
}: ActionListPayload): Promise<
  ActionListResult<
    Popup,
    {
      type: Field[]
      priority: Field[]
      extra_fields: { afterClose: Field[]; showPeriod: Field[] }
    }
  >
> {
  const { data } = await axios.get('/api/v1/popup', {
    cancelToken,
    params: {
      page,
      sort,
      count,
      filter,
      with: withItem,
    },
  })
  return data
}
