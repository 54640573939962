import axios from 'axios'

export default async function sortFixedArticle(payload: {
  ids: number[]
  section_id: number
}) {
  const { data } = await axios.post('/api/v1/fixed/fixed-article/sort', payload)

  return data
}
