import { MutationTree } from 'vuex'
import { ArticleState } from '../types'
import setArticle from './setArticle'
import setArticleField from './setArticleField'
import setArticleFieldsLoading from './setArticleFieldsLoading'

const mutations: MutationTree<ArticleState> = {
  setArticle,
  setArticleField,
  setArticleFieldsLoading,
}

export default mutations
