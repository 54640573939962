import deepFreeze from '@/helpers/deepFreeze'
import { Mutation } from 'vuex'
import { EpisodeState } from '../types'

const setEpisode: Mutation<EpisodeState> = (state, payload) => {
  console.log('payload', payload)
  state.activeEpisode = deepFreeze(payload)
}

export default setEpisode
