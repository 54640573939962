import { Channel } from 'laravel-echo/dist/channel'
import axios, { AxiosError, AxiosRequestConfig, AxiosInstance } from 'axios'
import * as Sentry from '@sentry/vue'
import { getApp } from '@/main'
import store from '@/store'
import { UserGetters } from '@/store/modules/user/types'
import { responseChannel } from '../../../websocket/channels'
import { asyncResponseEvent } from '../../../websocket/events'
import { ENABLED_SENTRY } from '@/config/base'

class ApiError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'ApiError'
  }
}

type RequestConfig = AxiosRequestConfig & {
  meta: {
    logLongRequestTimeoutId: number
    initedAt: string
  }
}

export const attachAxiosWSResponseInterceptor = (instance: AxiosInstance) => {
  const wsChannels: Record<string, Channel> = {}

  instance.interceptors.request.use(
    (config) => {
      const projectId = store.getters.getProjectId
      const projectUserId = (
        store.getters['user/getUserId'] as UserGetters['getUserId']
      )(projectId)

      if (config.useWS && config.url) {
        config.headers.common['X-Project-ID'] = projectId
        config.headers.common['X-Project-User-ID'] = projectUserId

        const { model, itemId } = config.useWS

        config.headers['X-Async-Key'] = `${model},${itemId}`
      }

      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  instance.interceptors.response.use(
    (response) => {
      const requestId = response.config.headers['X-Request-ID']
      const useWS = !!response.config.useWS
      const isAsyncResponse = response.config.useWS?.isAsyncResponse ?? true

      if (isAsyncResponse && requestId && useWS) {
        return new Promise((resolve, reject) => {
          const asyncKey = response.config.headers['X-Async-Key']

          const projectId = response.config.headers['X-Project-ID']
          const echoChannelName = responseChannel({
            projectId,
            key: asyncKey,
          })

          getApp.then((app) => {
            const errorTimeoutData: {
              id: number
              data: {
                code: number
                body: unknown
              }
            } = {
              id: 0,
              data: {
                code: 0,
                body: null,
              },
            }

            let isFinishedAsyncResponse = false
            let asyncResponseApiTimeoutId = 0

            const url = `/api/v1/async-response/${requestId}/`

            const fallbackTimeoutId = window.setTimeout(() => {
              const getAsyncResponse = () => {
                if (isFinishedAsyncResponse) return

                instance
                  .get(url, {
                    useRetry: true,
                  })
                  .then(({ data: { body, code } }) => {
                    errorTimeoutData.data = {
                      body,
                      code,
                    }

                    if (code === 202) {
                      if (!errorTimeoutData.id) {
                        errorTimeoutData.id = window.setTimeout(() => {
                          executor(
                            errorTimeoutData.data.code,
                            errorTimeoutData.data.body
                          )
                        }, 5 * 60e3)
                      }

                      asyncResponseApiTimeoutId = window.setTimeout(() => {
                        getAsyncResponse()
                      }, 3e3)
                    } else {
                      executor(code, body)
                    }
                  })
                  .catch((error) => {
                    if (axios.isCancel(error)) {
                      reject(error)
                    } else {
                      executor(error.response?.status, error.response?.data)
                    }
                  })
              }

              getAsyncResponse()
            }, 3e3)

            const executor = (code: number, body: unknown) => {
              const customConfig = response.config as RequestConfig | undefined

              wsChannels[echoChannelName].stopListening(
                asyncResponseEvent,
                wsChannelListenner
              )
              window.clearTimeout(fallbackTimeoutId)
              window.clearTimeout(errorTimeoutData.id)
              window.clearTimeout(asyncResponseApiTimeoutId)

              if (isFinishedAsyncResponse) return

              isFinishedAsyncResponse = true

              if (code !== 202 && code > 199 && code < 300) {
                resolve({ ...response, data: body })
              } else {
                if (ENABLED_SENTRY) {
                  Sentry.captureException(
                    new ApiError(
                      JSON.stringify(
                        {
                          requestId,
                          initedAt: customConfig?.meta.initedAt,
                          url: customConfig?.url,
                          asyncResponseUrl: url,
                          method: customConfig?.method,
                          statusCode: code,
                          body,
                        },
                        null,
                        2
                      )
                    )
                  )
                }

                reject({
                  config: response.config,
                  request: response.request,
                  isAxiosError: false,
                  message: `${code} Async Response`,
                  response: {
                    data: body,
                    headers: response.headers,
                    status: code,
                    config: response.config,
                  },
                })
              }
            }

            const wsChannelListenner = ({
              code,
              body,
              request_id: requestIdFromWS,
            }: {
              code: number
              body: unknown
              request_id: string
              socketId: string
            }) => {
              if (requestId !== requestIdFromWS) return

              executor(code, body)
            }

            if (!wsChannels[echoChannelName]) {
              wsChannels[echoChannelName] = app.$echo.channel(echoChannelName)
            }

            wsChannels[echoChannelName].listen(
              asyncResponseEvent,
              wsChannelListenner
            )
          })
        })
      }

      return response
    },
    (error: AxiosError) => {
      return Promise.reject(error)
    }
  )
}
