import { ActionListPayload, ActionListResult } from '@/types/action'
import { RecipeIngredient } from '@/types/recipe'
import axios from 'axios'

export default async function fetchRecipeIngredients({
  filter,
  withItem,
}: ActionListPayload): Promise<ActionListResult<RecipeIngredient>> {
  const { data } = await axios.get(`/api/v1/recipe-ingredient/`, {
    params: {
      with: withItem,
      filter,
    },
  })
  return data
}
