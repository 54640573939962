import { TagEditData, TagEditParent } from '@/types/tag'
import axios from 'axios'

export default async function editTag({
  id,
  tag,
}: {
  id: number
  tag?: Partial<TagEditData> | TagEditParent
}) {
  await axios.put(`/api/v1/tag/${id}/`, {
    ...tag,
  })
}
