import { ActionItemResult } from '@/types/action'
import { PlanCompare } from '@/types/planCompare'
import axios from 'axios'

export default async function createComparePlan(
  payload: Partial<Pick<PlanCompare, 'year' | 'month' | 'users' | 'views'>>
): Promise<ActionItemResult<PlanCompare>> {
  const { data } = await axios.post('/api/v1/plan-compare/', payload)
  return data
}
