import axios from 'axios'

export default async function exportArticles(params: {
  filter?: string | undefined
  page?: number
  sort?: string
  count?: number
}): Promise<Blob> {
  const { data } = await axios.get('/api/v1/article/export', {
    params,
    responseType: 'blob',
  })

  return data
}
