import { ArticleBlock } from '@/types/article'

export default function transformFactToNote(
  item: Partial<ArticleBlock>
): Pick<ArticleBlock, 'widget'> {
  if (item.widget?.name === 'note' || item.widget?.name === 'fact') {
    return {
      ...item,
      widget: {
        name: 'fact',
        params: {
          style: item.widget.params?.style || 'default',
          fact: item.widget.params?.text || item.widget.params?.fact,
        },
      },
    }
  }

  return item
}
