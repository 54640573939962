import axios from 'axios'
import { StatiscticArticle, StatisticPayload } from '@/types/statistics'
import { ActionListResult } from '@/types/action'

export default async function fetchStatisticViews(
  params: StatisticPayload
): Promise<ActionListResult<StatiscticArticle>> {
  const { data } = await axios.get('/api/v1/statistic/article/views/', {
    params,
  })
  return data
}
