import { ActionItemResult } from '@/types/action'
import { User } from '@/types/user'
import axios from 'axios'

export default async function fetchUser({
  id,
  token,
}: {
  id: number
  token?: string
}): Promise<ActionItemResult<User>> {
  const { data } = await axios.get(`/api/v1/user/${id}`, {
    headers: {
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    },
    useRetry: false,
  })
  return data
}
