import { ActionListPayload, ActionListResult } from '@/types/action'
import { Recipe, RecipeResponse } from '@/types/recipe'
import axios from 'axios'

export default async function fetchRecipes({
  filter,
  withItem,
  count,
  sort,
  page,
  cancelToken,
}: ActionListPayload): Promise<
  ActionListResult<Recipe, RecipeResponse['meta']['fields']>
> {
  const { data } = await axios.get('/api/v1/recipe/', {
    cancelToken,
    params: {
      page,
      sort,
      count,
      filter,
      with: withItem,
    },
  })
  return data
}
