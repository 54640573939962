import { ActionListPayload, ActionListResult } from '@/types/action'
import { RecipeProduct } from '@/types/recipe'
import axios from 'axios'

export default async function searchRecipeProducts({
  cancelToken,
  withItem,
  ...params
}: ActionListPayload & {
  term?: string
}): Promise<ActionListResult<RecipeProduct>> {
  const { data } = await axios.get('/api/v1/recipe-product/search', {
    cancelToken,
    params: {
      ...params,
      with: withItem,
    },
  })
  return data
}
