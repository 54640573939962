import { ActionItemResult } from '@/types/action'
import { PollChoice } from '@/types/pollChoice'
import axios from 'axios'

export default async function createPollChoice({
  label,
  poll_id,
  sort,
}: {
  label: string
  poll_id: number
  sort?: number
}): Promise<ActionItemResult<PollChoice>> {
  console.log('createPoll')
  const { data } = await axios.post('/api/v1/poll-choice/', {
    label,
    poll_id,
    sort,
  })
  return data
}
