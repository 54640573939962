import { Getter } from 'vuex'
import { RootState } from '@/store/types'
import { ArticleGetters, ArticleState } from '../types'

const articleTypeById: Getter<ArticleState, RootState> = (state) => {
  const getter: ArticleGetters['articleTypeById'] = (id) =>
    state.fields.types[id]

  return getter
}

export default articleTypeById
