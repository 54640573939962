import { ActionListPayload, ActionListResult } from '@/types/action'
import { Author } from '@/types/author'
import axios from 'axios'

export default async function searchAuthors({
  withItem,
  cancelToken,
  ...params
}: ActionListPayload & { term?: string }): Promise<ActionListResult<Author>> {
  const { data } = await axios.get('/api/v1/author/search/', {
    cancelToken,
    params: {
      ...params,
      with: withItem,
    },
  })
  return data
}
