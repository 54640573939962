import { Getter } from 'vuex'
import { RootState } from '@/store/types'
import { SocialDistributionState } from '../types'

const socialDistributionItems: Getter<SocialDistributionState, RootState> = (
  state
) => {
  return state.items.value
}

export default socialDistributionItems
