import axios from 'axios'
import { Podcast, PodcastType } from '@/types/podcast'

export default async function createPodcast(payload: {
  name: string
  code: string
  format: PodcastType
}): Promise<{ item: Podcast }> {
  const { data } = await axios.post<{ item: Podcast }>(
    '/api/v1/podcast/',
    payload
  )
  return data
}
