import { Getter } from 'vuex'
import { SidebarGetters, SidebarState } from '../types'
import { RootState } from '@/store/types'

const getSidebar: Getter<SidebarState, RootState> = (state) => {
  const getter: SidebarGetters['getSidebar'] = ({ type, status }) =>
    state[status].find((current) => current.type === type)

  return getter
}

export default getSidebar
