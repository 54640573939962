import axios from 'axios'
import { ActionGroupedListResult } from '@/types/action'
import { SocialDistribution, SocialGroup } from '@/types/socialDistribution'
import getSocialDistributionStatus from '@/helpers/getSocialDistributionStatus'

type ResponseSocialDistribution = ActionGroupedListResult<
  Record<string, SocialDistribution[]>,
  SocialDistribution,
  { channels: SocialGroup[] }
>

export default async function fetchSocialDistribution({
  filter,
  count,
}: {
  filter: string
  count?: number
}): Promise<ResponseSocialDistribution> {
  const { data } = await axios.get<ResponseSocialDistribution>(
    '/api/v1/social-distribution/',
    {
      params: {
        count,
        filter,
      },
    }
  )
  console.log(data)
  data.items.forEach((item) => {
    item.cid = item.id
    item.status = getSocialDistributionStatus({
      status: item.status,
      activeFrom: item.active_from,
    })
  })

  return data
}
