import { Getter } from 'vuex'
import { UserGetters, UserState } from '../types'
import { RootState } from '@/store/types'

const userHasRole: Getter<UserState, RootState> = (
  _,
  { getProjectAccessUserRoles }: UserGetters
) => {
  const getter: UserGetters['userHasRole'] = (value) => {
    if (Array.isArray(value)) {
      return getProjectAccessUserRoles().some((role) => value.includes(role))
    }

    return getProjectAccessUserRoles().includes(value)
  }

  return getter
}

export default userHasRole
