import { ActionItemResult } from '@/types/action'
import { Test } from '@/types/test'
import axios from 'axios'

export default async function createTest({
  name,
  type,
}: {
  name: string
  type: number
}): Promise<ActionItemResult<Test>> {
  const { data } = await axios.post('/api/v1/test/', { name, type })
  return data
}
