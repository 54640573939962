import { ActionListPayload, ActionListResult } from '@/types/action'
import { Test } from '@/types/test'
import axios from 'axios'

export default async function fetchTests({
  page,
  count,
  filter,
  sort,
  withItem,
  cancelToken,
}: ActionListPayload): Promise<ActionListResult<Test>> {
  const { data } = await axios.get('/api/v1/test/', {
    cancelToken,
    params: {
      page,
      count,
      filter,
      sort,
      with: withItem,
    },
  })
  return data
}
