import axios from 'axios'
import { Question, QuestionCreated } from '@/types/test'
import { ActionItemResult } from '@/types/action'

export default async function createTestQuestion(
  item: QuestionCreated
): Promise<ActionItemResult<Question>> {
  const { data } = await axios.post('/api/v1/test-question/', item)
  return data
}
