import { ActionListPayload, ActionListResult } from '@/types/action'
import { Poll } from '@/types/poll'
import axios from 'axios'

export default async function fetchPolls({
  page,
  count,
  withItem,
  filter,
  sort,
  cancelToken,
}: ActionListPayload): Promise<ActionListResult<Poll>> {
  const { data } = await axios.get('/api/v1/poll/', {
    cancelToken,
    params: { page, count, sort, with: withItem, filter },
  })

  return data
}
