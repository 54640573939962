import { ActionItemResult } from '@/types/action'
import { Video } from '@/types/widget/video'
import axios from 'axios'

export default async function fetchVideoWidget({
  id,
  withItem,
}: {
  id: number
  withItem?: string
}) {
  const { data } = await axios.get<ActionItemResult<Video>>(
    `/api/v1/video/${id}`,
    {
      params: {
        with: withItem,
      },
    }
  )

  data.item.previewImage = data.item.previewImage || null

  return data
}
