import { PollChoiceEditData } from '@/types/pollChoice'
import axios from 'axios'

export default async function updatePollMass({
  data,
}: {
  data: PollChoiceEditData[]
}) {
  await axios.put(`/api/v1/poll-choice/mass/`, { items: data })
}
