import axios from 'axios'

export default async function recover(email: string): Promise<any> {
  const { data } = await axios.post(
    '/api/v1/recover/',
    {
      email,
    },
    {
      autoLogout: false,
      useRetry: false,
      withAuthorizationHeader: false,
      authHostAsBaseUrl: true,
    }
  )

  return data
}
