import axios from 'axios'
import { ActionListPayload, ActionListResult } from '@/types/action'
import { Tag } from '@/types/tag'

export default async function fetchTags({
  page,
  count,
  withItem,
  select,
  sort,
  filter,
  cancelToken,
}: ActionListPayload): Promise<ActionListResult<Tag>> {
  const { data } = await axios.get('/api/v1/tag/', {
    cancelToken,
    params: { page, count, with: withItem, select, sort, filter },
  })

  return data
}
