import { Answer } from '@/types/test'
import axios from 'axios'

export default async function editTestQuestionAnswer({
  id,
  item,
}: {
  id: number | undefined
  item: Answer
}) {
  await axios.put(`/api/v1/test-question-answer/${id}`, { ...item })
}
