import axios from 'axios'
import { Episode } from '@/types/episode'
import { ActionListPayload } from '@/types/action'

export default async function fetchEpisodes({
  cancelToken,
  ...params
}: ActionListPayload): Promise<{
  items: Episode[]
  meta: { total_count: number }
}> {
  const { data } = await axios.get<{
    items: Episode[]
    meta: { total_count: number }
  }>('/api/v1/podcast-episode/', {
    params,
    cancelToken,
  })

  return data
}
