import axios from 'axios'
import transformFactToNote from '@/helpers/transformFactToNote'
import { ActionItemResult } from '@/types/action'
import { ArticleBlock } from '@/types/article'

export default async function createBlock({
  item_id,
  ...payload
}: { item_id: number } & Partial<ArticleBlock>): Promise<
  ActionItemResult<ArticleBlock>
> {
  const {
    data: { id },
  } = await axios.post(
    `/api/v1/rich-html-blocks/`,
    {
      ...transformFactToNote(payload),
      item_id,
    },
    {
      autoLogout: false,
      useWS: {
        model: 'richHtmlBlock',
        itemId: item_id,
      },
    }
  )

  const { data } = await axios.get(`/api/v1/rich-html-blocks/${id}/`)

  return data
}
