import initDialog from './dialogs/cosmohint'
// import icon from './icons/cosmohint.png'
// const iconUrl = require('./icons/cosmohint.png')
/* eslint-disable */
export default function initPlugin() {
  CKEDITOR.plugins.add('cosmohint', {
    icons: 'cosmohint',
    requires: ['dialog', 'fakeobjects'],
    init: function (editor) {
      editor.on('doubleclick', function(e){
        const element = e.data.element;

        if (element.is('span') && element.hasClass('cosmohint')) {
          e.data.dialog = 'cosmohintDialog';
        }
      });

      editor.lang.fakeobjects.cosmohint = 'Всплывающая подсказка';

      // Define an editor command that opens our dialog.
      editor.addCommand('cosmohint', new CKEDITOR.dialogCommand('cosmohintDialog'));

      // Create a toolbar button that executes the above command.
      editor.ui.addButton('cosmohint', {

        // The text part of the button (if available) and tooptip.
        label: 'Вставить всплывающую подсказку',

        // The command to execute on click.
        command: 'cosmohint',

        icon: 'cosmohint',

        // The button placement in the toolbar (toolbar group name).
        toolbar: 'insert'
      });

      // Register our dialog file. this.path is the plugin folder path.
      // CKEDITOR.dialog.add('cosmohintDialog', this.path + 'dialogs/cosmohint.js');
      initDialog();
    },
  })
}
