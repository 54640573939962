import { ActionListPayload } from '@/types/action'
import axios from 'axios'

export default async function fetchSendsaySegment({
  ...params
}: ActionListPayload = {}) {
  const { data } = await axios.get(`/api/v1/sendsay-segment/`, {
    params,
  })
  return {
    items: [data.meta.main, ...data.items],
    total_count: data.meta.total_count + 1,
  }
}
