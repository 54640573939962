import { Mutation } from 'vuex'
import { SocialDistribution } from '@/types/socialDistribution'
import { SocialDistributionState } from '../types'

const setSocialDistribution: Mutation<SocialDistributionState> = (
  state,
  payload: SocialDistribution
) => {
  const itemIndex = state.items.value.findIndex(
    (item) => item.cid === payload.cid
  )

  if (itemIndex > -1) {
    state.items.value.splice(itemIndex, 1, payload)
  }
}

export default setSocialDistribution
