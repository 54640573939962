import axios from 'axios'

export default async function updatePollChoice({
  id,
  sort,
  poll_id,
  label,
}: {
  id: number
  label: string
  poll_id: number
  sort: number
}) {
  await axios.put(`/api/v1/poll-choice/${id}/`, {
    id,
    sort,
    poll_id,
    label,
  })
}
