import axios, { CancelTokenSource } from 'axios'
import { Field } from '@/types/action'

let cancelTokenSource: CancelTokenSource | null = null

export default async function fetchArticleMeta(
  projectId?: number
): Promise<Field[]> {
  cancelTokenSource?.cancel()
  cancelTokenSource = axios.CancelToken.source()
  const headers: Record<string, string | number> = {}

  if (projectId) {
    headers['X-Project-ID'] = projectId
  }

  const {
    data: {
      meta: {
        fields: { type },
      },
    },
  } = await axios.get('/api/v1/article/meta', {
    cancelToken: cancelTokenSource.token,
    headers,
  })

  return type
}
