import axios from 'axios'
import { PreviewParamsArticle } from '@/types/mailing'

export default async function postMailingPreview(payload: {
  articles: PreviewParamsArticle[]
  customBlock: {
    description: string
    button: { url: string; caption: string }
    preheader: string
  }
}) {
  const { data } = await axios.post<string>(
    '/api/v1/mailing/sendsay/preview/',
    payload
  )

  return data
}
