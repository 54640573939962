import axios from 'axios'
import { ImagePurpose } from '@/types/image'
import { ActionListResult } from '@/types/action'

export default async function fetchImagePurposes(): Promise<
  ActionListResult<ImagePurpose>
> {
  const { data } = await axios.get(`/api/v1/image/purposes`)

  return data
}
