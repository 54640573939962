import Vue, { PluginObject } from 'vue'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import axios from 'axios'
import { SIRIUS_DOMAIN } from '@/config/base'

export const WebSocketPlugin: PluginObject<undefined> = {
  install() {
    const options = {
      namespace: window.location.search.includes('test-without-ws')
        ? 'TEST'
        : 'App\\Events\\WebSockets',
      broadcaster: 'pusher',
      key: 'websocketkey',
      cluster: 'mt1',
      wsHost: SIRIUS_DOMAIN,
      autoConnect: true,
      enabledTransports: ['ws', 'wss'],
    }

    window.Pusher = Pusher
    const echo = new Echo(options)

    axios.interceptors.request.use((config) => {
      const socketId = echo.socketId()

      if (socketId) {
        config.headers.common['X-Socket-ID'] = socketId
      }

      return config
    })

    Vue.prototype.$echo = echo
  },
}
