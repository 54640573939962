import { ActionItemResult } from '@/types/action'
import { Author } from '@/types/author'
import axios from 'axios'
import editAuthor from './editAuthor'

export default async function createAuthor({
  projectId,
  code,
  ...payload
}: Pick<Author, 'first_name' | 'last_name'> &
  Partial<Pick<Author, 'user_id' | 'code'>> & {
    user_id?: number
    projectId?: number
  }) {
  const headers: Record<string, string | number> = {}

  if (projectId) {
    headers['X-Project-ID'] = projectId
  }

  const { data: createdItemAuthor } = await axios.post<
    ActionItemResult<Author>
  >('/api/v1/author/', payload, {
    headers,
  })

  if (code) {
    const updatedItemAuthor = await editAuthor({
      id: createdItemAuthor.item.id,
      data: { code },
    })

    return updatedItemAuthor
  }

  return createdItemAuthor
}
