import axios from 'axios'
import { ActionItemResult } from '@/types/action'
import { ProjectID } from '@/types/platform'
import { User } from '@/types/user'

export default async function createUser({
  email,
  roles,
  projectId,
  first_name,
  last_name,
}: {
  last_name?: string
  first_name?: string
  email: string
  roles: string[]
  projectId?: ProjectID
}): Promise<User> {
  const headers: Record<string, string | number> = {}

  if (projectId) {
    headers['X-Project-ID'] = projectId
  }

  const { data } = await axios.post(
    '/api/v1/cms-create/',
    {
      email,
      roles,
      first_name,
      last_name,
    },
    {
      headers,
      useRetry: false,
      authHostAsBaseUrl: true,
    }
  )
  return data
}
