import Vue from 'vue'
import Vuex from 'vuex'
import { RootState } from './types'
import modules from './modules'
import state from './state'
import mutations from './mutations'
import getters from './getters'

Vue.use(Vuex)

const store = new Vuex.Store<RootState>({
  state,
  mutations,
  getters,
  modules,
})

export default store
