import { ActionListPayload } from '@/types/action'
import axios from 'axios'

export default async function exportStatisticArticleSearchViews({
  cancelToken,
  ids,
  ...params
}: ActionListPayload & {
  period?: string
  month?: number
  year?: number
  from?: string
  to?: string
  ids?: number[]
}): Promise<Blob> {
  const { data } = await axios.get(
    '/api/v1/statistic/article/search-views/export/',
    {
      cancelToken,
      params: { ...params, ids: ids?.join(',') },
      responseType: 'blob',
    }
  )

  return data
}
