import axios from 'axios'
import { ActionListResult } from '@/types/action'
import { CommentAdminUser } from '@/types/user'

export default async function fetchCommentAdminUsers(
  projectId: number
): Promise<ActionListResult<CommentAdminUser>> {
  const headers: Record<string, string | number> = {}

  if (projectId) {
    headers['X-Project-ID'] = projectId
  }
  const { data } = await axios.get('/api/v1/user/comment-admin-users/', {
    headers,
  })

  return data
}
