var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('aside',{class:[
    'notifications',
    {
      notifications_mobile: _vm.$vuetify.breakpoint.xsOnly,
      notifications_desktop: !_vm.$vuetify.breakpoint.xsOnly,
      notifications_showed: _vm.notifications.length > 0,
      [`notifications_page_${_vm.$route.name}`]: !!_vm.$route.name,
    },
  ]},[_c('div',{staticClass:"notifications__wrapper"},[_c('transition-group',{staticClass:"notifications__inner",attrs:{"tag":"div","name":"notifications__notification"}},_vm._l((_vm.notifications),function(notification){return _c('div',{key:notification.id,staticClass:"notifications__notification overflow-hidden"},[_c('v-alert',{staticClass:"d-flex flex-column justify-center flex-shrink-0 text-subtitle-2 py-2 mb-0 white--text",attrs:{"type":notification.alertType,"dismissible":notification.dismissible,"color":notification.alertColor,"dense":"","min-height":"48"},on:{"input":function($event){return _vm.closeNotification(notification.id)}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [(notification.type === 'loading')?_c('v-progress-circular',{staticClass:"mr-4",attrs:{"indeterminate":"","size":"22","width":"2"}}):(notification.type === 'success')?_c('v-icon',{staticClass:"mr-4",attrs:{"size":"24"}},[_vm._v(" mdi-check-circle-outline ")]):_c('v-icon',{staticClass:"mr-4",attrs:{"size":"24"}},[_vm._v(" mdi-alert-circle-outline ")])]},proxy:true}],null,true)},[(notification.type === 'required-field')?[_vm._v(" Поле "),_c('span',{staticClass:"font-weight-bold",domProps:{"innerHTML":_vm._s(notification.title)}}),_vm._v(" обязательно к заполнению ")]:_c('span',{domProps:{"innerHTML":_vm._s(notification.title)}})],2)],1)}),0)],1),_c('button',{staticClass:"notifications__close-all-button",attrs:{"type":"button"},on:{"click":_vm.closeAllNotifications}},[_c('span',{staticClass:"notifications__close-all-button-text"},[_vm._v(" Закрыть все ")]),_c('span',{staticClass:"notifications__close-all-button-icon"})])])
}
var staticRenderFns = []

export { render, staticRenderFns }