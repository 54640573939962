import { RecipeResponse } from '@/types/recipe'
import axios from 'axios'

export default async function fetchRecipe({
  id,
  withItem,
}: {
  id: number
  withItem?: string
}): Promise<RecipeResponse> {
  const { data } = await axios.get(`/api/v1/recipe/${id}`, {
    params: {
      with: withItem,
    },
  })

  return data
}
