import axios from 'axios'
import { Block } from '@/types/block'

export default async function updateBlock({
  id,
  ...payload
}: Partial<Block>): Promise<Block> {
  const { data } = await axios.put(`/api/v1/block/${id}/`, payload)

  return data
}
