import { ActionItemResult } from '@/types/action'
import { Contest } from '@/types/contest'
import axios from 'axios'

export default async function createContest({
  name,
  active_from,
  active_to,
}: {
  name: string
  active_from: string | null
  active_to: string | null
}): Promise<ActionItemResult<Contest>> {
  const { data } = await axios.post('/api/v1/contest/', {
    name,
    active_from,
    active_to,
  })
  return data
}
