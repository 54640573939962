import { ActionListPayload, ActionListResult } from '@/types/action'
import { Contest } from '@/types/contest'
import axios from 'axios'

export default async function fetchContests({
  filter,
  page,
  withItem,
  count,
  sort,
  cancelToken,
}: ActionListPayload): Promise<ActionListResult<Contest>> {
  const { data } = await axios.get('/api/v1/contest/', {
    cancelToken,
    params: {
      page,
      sort,
      count,
      filter,
      with: withItem,
    },
  })
  return data
}
