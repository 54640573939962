import axios from 'axios'

import BrandWidget from '@/types/widget/brandWidget'
import { ActionItemResult } from '@/types/action'

export default async function createBrandWidget(payload: BrandWidget) {
  const { data } = await axios.post<ActionItemResult<BrandWidget>>(
    `/api/v1/brand-widget/`,
    payload
  )

  return data
}
