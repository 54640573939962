import { ActionItemResult } from '@/types/action'
import { RecipeCompilation } from '@/types/recipe'
import axios from 'axios'

export default async function createRecipeCompilation(
  recipeCompilation: Partial<RecipeCompilation>
): Promise<ActionItemResult<RecipeCompilation>> {
  const { data } = await axios.post(
    '/api/v1/recipe-compilation/',
    recipeCompilation
  )
  return data
}
