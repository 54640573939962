import { ActionItemResult } from '@/types/action'
import { Expert } from '@/types/expert'
import axios from 'axios'

export default async function createExpert(
  payload: Partial<Omit<Expert, 'id'>>
): Promise<ActionItemResult<Expert>> {
  const { data } = await axios.post('/api/v1/expert/', payload)

  return data
}
