import axios from 'axios'

import { BlockWithoutPositions, BlockCode } from '@/types/block'

export default async function fetchBlockByCode<R = BlockWithoutPositions>({
  blockCode,
}: {
  blockCode: BlockCode
}): Promise<R> {
  const { data } = await axios.get<R>(`/api/v1/block/by-code/${blockCode}/`)

  return data
}
