import axios from 'axios'

export default async function updateComment({
  id,
  status,
  projectId,
}: {
  id: number
  status: number
  projectId: number
}) {
  const headers: Record<string, string | number> = {}

  if (projectId) {
    headers['X-Project-ID'] = projectId
  }
  await axios.put(`/api/v1/comment/${id}/`, {
    status,
    headers,
  })
}
