import { Test } from '@/types/test'
import axios from 'axios'

export default async function editTest({
  id,
  test,
}: {
  id: number
  test: Test
}) {
  await axios.put(`/api/v1/test/${id}`, { ...test })
}
