import { ProjectID } from '@/types/platform'
import { UserEditData } from '@/types/user'
import axios from 'axios'

export default async function editUser({
  id,
  user,
  projectId,
}: {
  id: number
  user: UserEditData
  projectId?: ProjectID
}) {
  const headers: Record<string, string | number> = {}

  if (projectId) {
    headers['X-Project-ID'] = projectId
  }

  await axios.put(`/api/v1/user/${id}`, { ...user }, { headers })
}
