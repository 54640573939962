import { ActionItemResult } from '@/types/action'
import { Author } from '@/types/author'
import axios from 'axios'

export default async function editAuthor({
  id,
  data,
}: {
  id: number
  data: Partial<Author>
}) {
  const { data: itemAuthor } = await axios.put<ActionItemResult<Author>>(
    `/api/v1/author/${id}`,
    data
  )

  return itemAuthor
}
