import axios from 'axios'

import { Article } from '@/types/article'
import { dateToMoscowTime, formatDateToMoscowTime } from '@/lib/date'

export default async function createArticleFromVideo(payload: {
  video_id: number
  name: string
  image_id: number | null
}): Promise<{ item: Article }> {
  const { data } = await axios.post<{ item: Article }>(
    '/api/v1/article/video',
    {
      ...payload,
      active_from: formatDateToMoscowTime(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      order_date: dateToMoscowTime(new Date()).toISOString(),
    }
  )

  return data
}
