/* eslint-disable */
export default function initDialog() {
  CKEDITOR.dialog.add('cosmohintDialog', function (editor) {
    return {
      // Basic properties of the dialog window: title, minimum size.
      title: 'Вставка всплывающей подсказки',
      minWidth: 400,
      minHeight: 200,

      // Dialog window contents definition.
      contents: [
        {
          // Definition of the Basic Settings dialog tab (page).
          id: 'tab-basic',
          label: 'Всплывающая подсказка',

          // The tab contents.
          elements: [
            {
              type: 'text',
              id: 'title',
              label: 'Заголовок',
            },
            {
              type: 'html',
              id: 'text',
              html: '<label>Текст <span style="color:red">*</span></label><br>\
                                <textarea style="min-height:300px;" class="cke_dialog_ui_input_textarea" name="ckeditorHint" id="ckeditorHint" ></textarea>',
              onLoad: function () {
                CKEDITOR.replace('ckeditorHint', {
                  toolbar_Quote: [['Bold', 'Italic', 'Link', 'NumberedList', 'BulletedList', 'Removeformat']],
                  toolbar: 'Quote'
                })
              },
              validate: function () {
                const getData = CKEDITOR.instances.ckeditorHint.getData()
                if (!getData.length) {
                  alert('Текст должен быть заполнен')
                  return false
                }
              },
            },
          ],
        },
      ],

      // This method is invoked once a user clicks the OK button, confirming the dialog.
      onOk: function () {
        let data = {
          title: this.getValueOf('tab-basic', 'title'),
          text: CKEDITOR.instances.ckeditorHint.getData(),
        }

        let span
        span = editor.getSelection().getStartElement()
        let selected = editor.getSelection().getSelectedText()

        if (span && span.hasClass('cosmohint')) {
          span.data('title', data.title)
          span.data('text', data.text)
        } else {
          editor.insertHtml(
              '<span class="cosmohint" data-title="' +
              data.title +
              '" data-text="' +
              data.text.replaceAll('"', '&quot;') +
              '">' +
              selected +
              '</span>'
          )
        }
      },
      onShow: function () {
        let textLimit = function () {
          let limitExceeded = false
          if (CKEDITOR.instances.ckeditorHint.getData().length > 200) {
            limitExceeded = true
          }
        }

        let span
        span = editor.getSelection().getStartElement()
        let selected = editor.getSelection().getSelectedText()

        if (span && span.hasClass('cosmohint')) {
          let data = {}
          data.title = span.data('title')
          data.text = span.data('text')

          for (let key in data) {
            switch (key) {
              case 'text':
                CKEDITOR.instances.ckeditorHint.setData(data[key])
                window.ckeditor_quote_prevtext = data[key]
                break
              default:
                this.setValueOf('tab-basic', key, data[key])
            }
          }
        } else if (selected.trim() == '') {
          alert(
              'Сначала выделите часть текста, после этого еще раз нажмите на виджет'
          )
          CKEDITOR.dialog.getCurrent().hide()
        } else {
          CKEDITOR.instances.ckeditorHint.setData('')
        }
      },
    }
  })
}
