export const socials: Record<
  'vk' | 'fb' | 'twitter' | 'ok' | 'viber' | 'tg' | 'youtube',
  {
    title: string
    bgColor?: string
    iconUrl?: string
  }
> = {
  vk: {
    title: 'Вконтакте',
    bgColor: '#07f',
    iconUrl: require('@/assets/images/social-distribution/vk.svg'),
  },
  fb: {
    title: 'Facebook',
    bgColor: '#1877f2',
    iconUrl: require('@/assets/images/social-distribution/fb.svg'),
  },
  twitter: {
    title: 'X',
    bgColor: '#000000',
    iconUrl: require('@/assets/images/social-distribution/twitter.svg'),
  },
  ok: {
    title: 'Одноклассники',
    bgColor: '#EE8208',
    iconUrl: require('@/assets/images/social-distribution/ok.svg'),
  },
  viber: {
    title: 'Viber',
    bgColor: '#7360F2',
    iconUrl: require('@/assets/images/social-distribution/viber.svg'),
  },
  tg: {
    title: 'Telegram',
    bgColor: '#2aaaee',
    iconUrl: require('@/assets/images/social-distribution/tg.svg'),
  },
  youtube: {
    title: 'Youtube',
  },
}
