import { ActionListPayload, ActionListResult } from '@/types/action'
import { AdBannerPosition } from '@/types/advSwitch'
import axios from 'axios'

export default async function fetchAdSiteSection({
  page,
  count,
  withItem,
}: ActionListPayload): Promise<ActionListResult<AdBannerPosition>> {
  const { data } = await axios.get('/api/v1/ad-site-banner-position/', {
    params: { page, count, with: withItem },
  })

  return data
}
