import { ActionItemResult } from '@/types/action'
import { Image } from '@/types/image'
import axios from 'axios'

export default async function addImage({
  file,
}: {
  file: FormData
}): Promise<ActionItemResult<Image>> {
  const { data } = await axios.post(`/api/v1/image/`, file, {
    useRetry: false,
  })

  return data
}
