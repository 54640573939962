import axios from 'axios'
import { ActionListPayload, ActionListResult } from '@/types/action'
import { AdSiteSection } from '@/types/advSwitch'

export default async function fetchAdSiteSection({
  page,
  count,
  withItem,
}: ActionListPayload): Promise<ActionListResult<AdSiteSection>> {
  const { data } = await axios.get('/api/v1/ad-site-section/', {
    params: { page, count, with: withItem },
  })

  return data
}
