import { setStorageItemWithExpiry } from '@/helpers/localStorageHelpers'
import { AuthInfo } from '@/types/auth'
import { Mutation } from 'vuex'
import { AuthState } from '../types'

const setAuth: Mutation<AuthState> = (
  state,
  { token, id, permissions }: AuthInfo
) => {
  state.token = token
  state.userId = id
  state.permissions = Object.freeze(permissions)
  setStorageItemWithExpiry('authInfo', {
    token,
    id,
    permissions,
  })
}

export default setAuth
