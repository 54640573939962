import { ActionItemResult, ActionListPayload } from '@/types/action'
import { AdBannerPosition } from '@/types/advSwitch'
import axios from 'axios'

export default async function createBannerPosition({
  name,
  div_name,
}: ActionListPayload): Promise<ActionItemResult<AdBannerPosition>> {
  const { data } = await axios.post(`/api/v1/ad-site-banner-position/`, {
    name,
    div_name,
  })
  return data
}
