import { ActionItemResult } from '@/types/action'
import { Feature } from '@/types/feature'
import axios from 'axios'

export default async function fetchFeature({
  id,
  withItem,
}: {
  id: number
  withItem: string
}): Promise<ActionItemResult<Feature>> {
  const { data } = await axios.get(`/api/v1/feature-article/${id}`, {
    params: {
      with: withItem,
    },
  })
  return data
}
