import { ImageTransform } from './image'

export enum PopupType {
  PWA = 0,
  EMAIL_SUBSCRIPTION = 1,
  PUSH_SUBSCRIPTION = 2,
  SOCIAL_NETWORK_SUBSCRIPTION = 3,
  PROMO = 4,
  COOKIE_ALERT = 5,
}

export enum PopupPriority {
  MINIMUM = 0,
  STANDARD = 1,
  MAXIMUM = 2,
}

export enum PopupShowPeriod {
  ONCE = 0,
  MONTHLY = 1,
  WEEKLY = 2,
  DAILY = 3,
  ONCE_DAY = 4,
  EVERY_NEW_SESSION = 5,
}

export enum PopupAfterClose {
  HIDDEN_FOREVER = 0,
  HIDDEN_MONTH = 1,
  HIDDEN_WEEK = 2,
  HIDDEN_ONE_DAY = 3,
  HIDDEN_DAY = 4,
  HIDDEN_CURRENT_SESSION = 5,
}

export enum PopupShowType {
  DESCKTOP_AND_MOBILE = 1,
  DESCKTOP = 2,
  MOBILE = 3,
}

export enum PopupMaxLength {
  DESCKTOP = 70,
  MOBILE = 37,
}

export interface Popup {
  id: number
  active: number
  created_at: string
  active_from: null | string
  active_to: null | string
  type: PopupType | null
  priority: PopupPriority
  image: ImageTransform | null
  image_id: number | null
  views: number
  ctr: number
  clicks: number
  show_type: PopupShowType
  show_on_io: number
  show_on_main: number
  creator?: {
    first_name: string | null
    last_name: string | null
    email: string
  }
  extra_fields: {
    title: string | null
    titleMobile: string | null
    subTitle: string | null
    subTitleMobile: string | null
    collectEmailsForm: number
    buttonText: string | null
    buttonTextMobile: string | null
    buttonLink: string | null
    showButton: number
    sectionIds: number[] | null
    skipSectionIds: number[] | null
    trafficSources: string[] | null
    skipTrafficSources: string[] | null
    showToMailSubscribers: number
    showToPushSubscribers: number
    showToPWASubscribers: number
    showPeriod: PopupShowPeriod
    showPeriodNumberOfDays: number
    afterClose: PopupAfterClose
    afterCloseNumberOfDays: number
  }
}
