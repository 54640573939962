import { ActionItemResult } from '@/types/action'
import { RecipeGroup } from '@/types/recipe'
import axios from 'axios'

export default async function createRecipeIngredientsGroup(payload: {
  recipe_id: number
  sort: number
}): Promise<ActionItemResult<RecipeGroup>> {
  const { data } = await axios.post('/api/v1/recipe-ingredient-group/', payload)
  return data
}
