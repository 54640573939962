import axios from 'axios'
import { BlockCode, ShortBlockArticle } from '@/types/block'

export default async function fetchBlockArticle({
  articleProductionId,
  blockCode,
}: {
  articleProductionId: number
  blockCode: BlockCode
}): Promise<ShortBlockArticle> {
  const { data: blockArticle } = await axios.get<ShortBlockArticle>(
    `/api/v1/block-article/${blockCode}/${articleProductionId}`
  )

  return blockArticle
}
