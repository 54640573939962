import { ActionItemResult } from '@/types/action'
import { Video } from '@/types/widget/video'
import axios from 'axios'

export default async function updateVideoWidget({
  id,
  data,
}: {
  id: number
  data: Partial<Video>
}): Promise<ActionItemResult<Video>> {
  const { data: videoData } = await axios.put(`/api/v1/video/${id}`, data)

  return videoData
}
