import { ActionListPayload } from '@/types/action'
import axios from 'axios'

export default async function fetchStatisticMaterialGroup(
  params: ActionListPayload & {
    type?: string
    publish_date?: string | string[]
    views_date?: string | string[]
    page?: number
    count?: number
  }
): Promise<any> {
  const data = await axios.get(
    '/api/v1/statistic/clickhouse/by-content-type/views-by-article',
    {
      params,
    }
  )

  return data.data
}
