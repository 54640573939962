import { ActionListPayload, ActionListResult } from '@/types/action'
import { VideoPartner } from '@/types/widget/videoPartner'
import axios from 'axios'

export default async function fetchVideoPartners({
  filter,
  page,
  withItem,
  count,
  sort,
}: ActionListPayload): Promise<ActionListResult<VideoPartner>> {
  const { data } = await axios.get('/api/v1/video-partner/', {
    params: {
      page,
      sort,
      count,
      filter,
      with: withItem,
    },
  })
  return data
}
