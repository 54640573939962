import Vue, { PluginObject } from 'vue'

let initedRouterWatch = false

const vm = new Vue({
  data() {
    return {
      pageStatusCode: 200,
    }
  },
})

const PageStatusCodeMixin = Vue.extend({
  computed: {
    pageStatusCode() {
      return vm.pageStatusCode
    },
  },
  created() {
    if (!initedRouterWatch) {
      this.$router.onReady(() => {
        this.$router.beforeEach((to, from, next) => {
          this.$setPageStatusCode(200)
          next()
        })
      })

      initedRouterWatch = true
    }
  },
})

const PageStatusCodePlugin: PluginObject<undefined> = {
  install() {
    Vue.prototype.$setPageStatusCode = (code: number) => {
      vm.pageStatusCode = code
    }
  },
}

export { PageStatusCodeMixin, PageStatusCodePlugin }
