import axios from 'axios'
import { ActionItemResult } from '@/types/action'
import { Article, ArticleItemMetaFields } from '@/types/article'

export default async function fetchArticle({
  id,
  withItem,
}: {
  id: number
  withItem?: string
}) {
  const { data } = await axios.get<
    ActionItemResult<Article, ArticleItemMetaFields>
  >(`/api/v1/article/${id}`, {
    params: {
      with: withItem,
    },
  })

  data.item.draft_id = data.item.draft_id || data.item.id
  data.item.issue = data.item.issue || null
  data.item.c_use_verstka = data.item.use_verstka || 0

  return data
}
