import { Vue, Component } from 'vue-property-decorator'
import nestedFreeze from '@/helpers/nestedFreeze'
import events from '../../events'
import { NotificationOptions } from '../../types'

interface NotificationItem extends NotificationOptions {
  id: number
  autoCloseTimeoutId: number
  alertType?: string
  alertColor?: string
}

@Component
export default class Notifications extends Vue {
  private notifications: Readonly<NotificationItem[]> = []

  private closeNotification(id: number) {
    this.notifications = this.notifications.reduce<NotificationItem[]>(
      (acc, notification) => {
        if (notification.id === id) {
          window.clearTimeout(notification.autoCloseTimeoutId)
        } else {
          acc.push(notification)
        }

        return acc
      },
      []
    )
  }

  private closeAllNotifications() {
    this.notifications.forEach(({ id }) => {
      this.closeNotification(id)
    })
  }

  private mounted() {
    events.$on('remove', ({ id }: { id: number }) => {
      this.closeNotification(id)
    })

    events.$on(
      'add',
      ({
        type,
        autoCloseTime = type === 'loading' ? 0 : 3e3,
        dismissible = false,
        id,
        ...notification
      }: NotificationOptions & { id: number }) => {
        let autoCloseTimeoutId = 0

        if (autoCloseTime) {
          autoCloseTimeoutId = window.setTimeout(() => {
            this.closeNotification(id)
          }, autoCloseTime)
        }

        const notifications = this.notifications.concat([])
        const notificationIndex = id
          ? notifications.findIndex((notification) => notification.id === id)
          : -1

        const oldNotification =
          notificationIndex > -1 ? notifications[notificationIndex] : null

        let alertColor
        let alertType

        if (type === 'loading') {
          alertColor = 'light-blue darken-2'
        } else if (type === 'required-field') {
          alertType = 'error'
        } else {
          alertType = type
        }

        if (notificationIndex === -1) {
          notifications.unshift({
            ...notification,
            alertColor,
            alertType,
            type,
            dismissible,
            autoCloseTimeoutId,
            id,
          })
        } else {
          if (oldNotification?.autoCloseTimeoutId) {
            window.clearTimeout(oldNotification.autoCloseTimeoutId)
          }

          notifications.splice(notificationIndex, 1, {
            ...notification,
            alertColor,
            alertType,
            type,
            dismissible,
            autoCloseTimeoutId,
            id,
          })
        }

        this.notifications = nestedFreeze(notifications)
      }
    )
  }
}
