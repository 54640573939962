import { ActionListPayload, ActionListResult } from '@/types/action'
import { StatisticSearchViews } from '@/types/statistics'
import axios from 'axios'

export default async function fetchStatisticArticleSearchViews({
  cancelToken,
  ids,
  ...params
}: ActionListPayload & {
  period?: string
  month?: number
  year?: number
  from?: string
  to?: string
  ids?: number[]
}): Promise<ActionListResult<StatisticSearchViews>> {
  const { data } = await axios.get('/api/v1/statistic/article/search-views/', {
    cancelToken,
    params: { ...params, ids: ids?.join(',') },
  })

  return data
}
