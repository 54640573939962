import axios from 'axios'
import { ActionListPayload, ActionListResult } from '@/types/action'
import { AdvTag } from '@/types/advTag'

export default async function fetchAdvTags({
  withItem,
  filter,
  sort,
  cancelToken,
}: ActionListPayload): Promise<ActionListResult<AdvTag>> {
  const { data } = await axios.get('/api/v1/comparative-commerce/', {
    params: { filter, sort, with: withItem },
    cancelToken,
  })

  return data
}
