import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import urlCodeFromText from '@/helpers/urlCodeFromText'

@Component({
  inheritAttrs: false,
})
export default class UrlCodeTextarea extends Vue {
  @Prop({ type: Boolean, default: true }) private readonly autoGrow!: boolean
  @Prop({ type: Number, default: 2 }) private readonly rows!: number
  @Prop({ type: Number }) private readonly rowHeight!: number
  @Prop({ type: Boolean, default: true }) private readonly dense!: boolean
  @Prop({ type: Boolean, default: true }) private readonly outlined!: boolean
  @Prop({ type: Boolean, default: false }) private readonly disabled!: boolean
  @Prop({ type: String, default: '' }) private readonly label!: boolean
  @Prop({ type: String, required: true }) private readonly value!: string

  private localValue = ''

  private get localListeners() {
    const $listeners = { ...this.$listeners }

    delete $listeners.input

    return $listeners
  }

  private get localRowHeight() {
    return this.rowHeight || (this.dense ? 17 : 16)
  }

  private onInput(value: string) {
    this.localValue = urlCodeFromText(value)

    this.$emit('input', this.localValue)
  }

  private onBlur() {
    const value = this.localValue

    this.localValue = ''

    this.$nextTick(() => {
      this.localValue = value
    })
  }

  private mounted() {
    this.$watch(
      () => {
        return this.value
      },
      () => {
        this.localValue = this.value
      },
      { immediate: true }
    )
  }
}
