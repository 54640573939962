import { RootState } from '@/store/types'
import { Getter } from 'vuex'
import { ModalGetters, ModalState } from '../types'

const isShowModal: Getter<ModalState, RootState> = (state) => {
  const getter: ModalGetters['isShowModal'] = (value) => {
    return value === state.current?.name
  }

  return getter
}

export default isShowModal
