import axios from 'axios'
import { SocialDistribution } from '@/types/socialDistribution'
import htmlToText from '@/helpers/htmlToText'

type SocialDistributionItem = Partial<Omit<SocialDistribution, 'id'>> &
  Pick<SocialDistribution, 'id'>

export default async function updateSocialDistributions(
  items: SocialDistributionItem[]
) {
  await axios.put('/api/v1/social-distribution/mass/', {
    items: items.map((item) => ({
      ...item,
      text: item.text && htmlToText(item.text),
    })),
  })
}
