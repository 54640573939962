import { ActionItemResult } from '@/types/action'
import { ImageTransform } from '@/types/image'
import axios, { CancelToken } from 'axios'

export default async function changeImageTransform({
  id,
  responseStatusOnly,
  cancelToken,
  ...image
}: Pick<
  ImageTransform,
  | 'id'
  | 'crop_h'
  | 'crop_w'
  | 'crop_x'
  | 'crop_y'
  | 'model_id'
  | 'item_id'
  | 'image_id'
  | 'settings'
  | 'constructedImage'
> & {
  responseStatusOnly?: number
  cancelToken?: CancelToken
}): Promise<ActionItemResult<ImageTransform>> {
  const headers: Record<string, string> = {}

  if (responseStatusOnly) {
    headers['X-Response-Status-Only'] = responseStatusOnly.toString()
  }

  const { data } = await axios.put(`/api/v1/image-transform/${id}`, image, {
    headers,
    cancelToken,
  })

  return data
}
