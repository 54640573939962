import axios from 'axios'
import { ActionListPayload, ActionListResult } from '@/types/action'
import { Article } from '@/types/article'

export default async function fetchRepublishPlan({
  cancelToken,
  ...params
}: ActionListPayload): Promise<ActionListResult<Article>> {
  const { data } = await axios.get<ActionListResult<Article>>(
    '/api/v1/article/republish-plan/',
    {
      cancelToken,
      params,
    }
  )

  return data
}
