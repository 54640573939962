import { ActionItemResult } from '@/types/action'
import { Recipe } from '@/types/recipe'
import axios from 'axios'

export default async function updateRecipe(
  recipe: Pick<Recipe, 'id'> & Partial<Recipe>
): Promise<ActionItemResult<Recipe>> {
  const { data } = await axios.put(`/api/v1/recipe/${recipe.id}/`, {
    ...recipe,
  })

  return data
}
