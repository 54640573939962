import { RootState } from '@/store/types'
import { GetterTree } from 'vuex'
import { ModalState } from '../types'

import isShowModal from './isShowModal'
import currentModal from './currentModal'

const getters: GetterTree<ModalState, RootState> = {
  isShowModal,
  currentModal,
}

export default getters
