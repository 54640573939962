import axios, { AxiosError, AxiosInstance } from 'axios'
import { getApp } from '@/main'

export const attachAxiosErrorNotificationInterceptor = (
  instance: AxiosInstance
) => {
  instance.interceptors.response.use(
    (response) => response,
    async (error: AxiosError) => {
      if (axios.isCancel(error)) {
        return Promise.reject(error)
      }

      const customConfig = error?.config || {}
      const status = error.response?.status || 0
      const method = error.config.method?.toLowerCase()
      const isShowNotify = customConfig?.isShowNotify ?? true

      if (!isShowNotify) return Promise.reject(error)

      // Проверка на 403 для метода PUT и показ сообщения
      if (method === 'put' && status === 403) {
        getApp.then((app) => {
          app.$notify({
            type: 'error',
            title: 'У вас нет доступа на редактирование раздела',
          })
        })
      }

      const errors = error?.response?.data as
        | Record<string, string[]>
        | undefined

      if (errors) {
        const [firstErrorKey] = Object.keys(errors)
        const firstErrorMessage =
          firstErrorKey &&
          Array.isArray(errors[firstErrorKey]) &&
          errors[firstErrorKey][0]

        if (firstErrorMessage) {
          getApp.then((app) => {
            app.$notify({
              type: 'error',
              title: firstErrorMessage,
            })
          })
        }
      }

      return Promise.reject(error)
    }
  )
}
