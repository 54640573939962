import { RecipeIngredient } from '@/types/recipe'
import axios from 'axios'

export default async function updateRecipeIngredient(
  payload: RecipeIngredient
): Promise<RecipeIngredient> {
  const { data } = await axios.put(
    `/api/v1/recipe-ingredient/${payload.id}/`,
    payload
  )
  return data
}
