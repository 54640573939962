import { Getter } from 'vuex'
import { UserGetters, UserState } from '../types'
import { RootState } from '@/store/types'
import { ProjectID } from '@/types/platform'

const getUserLocalId: Getter<UserState, RootState> = (
  state,
  getters,
  rootState
) => {
  const getter: UserGetters['getUserLocalId'] = (
    projectId: ProjectID = ProjectID[rootState.platform]
  ) => {
    if (projectId === ProjectID.im) {
      return state.current?.local_id || 0
    }

    return state.current?.projectAccessUsers[projectId]?.local_id || 0
  }

  return getter
}

export default getUserLocalId
