import { ActionTree } from 'vuex'
import { VideoWidgetState } from '../types'
import { RootState } from '@/store/types'
import createVideoWidget from './createVideoWidget'

const actions: ActionTree<VideoWidgetState, RootState> = {
  createVideoWidget,
}

export default actions
