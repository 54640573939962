import { Action } from 'vuex'
import { RootState } from '@/store/types'
import { VideoWidgetState } from '../types'
import axios from 'axios'

const createVideoWidget: Action<VideoWidgetState, RootState> = async (
  { commit },
  paylod
) => {
  commit('setProgress', 0)
  const { data } = await axios.post('/api/v1/video/', paylod.data, {
    onUploadProgress: (progressEvent) => {
      const progress = Math.round(
        (progressEvent.loaded / progressEvent.total) * 100
      )
      commit('setProgress', progress)
    },
    cancelToken: paylod.cancelToken || null,
  })
  return data
}

export default createVideoWidget
