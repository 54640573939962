import { ActionItemResult } from '@/types/action'
import { AdvTag } from '@/types/advTag'
import axios from 'axios'

export default async function fetchAdvTag({
  withItem,
  id,
}: {
  id: number
  withItem?: string
}): Promise<ActionItemResult<AdvTag>> {
  const { data } = await axios.get(`/api/v1/comparative-commerce/${id}`, {
    params: { with: withItem },
  })

  return data
}
