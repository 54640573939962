import axios from 'axios'
import { ContactPerson } from '@/types/contact'

export default async function updateContactPerson({
  id,
  ...payload
}: Pick<ContactPerson, 'id'> &
  Partial<Pick<ContactPerson, 'sort' | 'department_id'>>) {
  await axios.put(`/api/v1/contacts-person/${id}/`, payload)
}
