import axios from 'axios'

export default async function unblockItem(payload: {
  model: string
  item_id: number
  articleId: number
  field?: string
}) {
  await axios.post('/api/v1/blocking/remove/', payload, {
    useWS: {
      model: 'blockingRichHtmlBlock',
      itemId: payload.articleId,
      isAsyncResponse: false,
    },
  })
}
