import { Action } from 'vuex'
import { RootState } from '@/store/types'
import { UserState } from '../types'
import SiriusAPI from '@/api'

const fetchProfile: Action<UserState, RootState> = async ({
  commit,
  rootGetters,
}) => {
  const isAuthenticated = rootGetters['auth/isAuthenticated']

  if (!isAuthenticated) return

  const user = await SiriusAPI.user.fetchProfile()

  commit('setUserState', user)
}

export default fetchProfile
