import { ActionItemResult } from '@/types/action'
import { Tag } from '@/types/tag'
import axios from 'axios'

export default async function addTag({
  name,
}: {
  name: string
}): Promise<ActionItemResult<Tag>> {
  const { data } = await axios.post('/api/v1/tag/', { name })
  return data
}
