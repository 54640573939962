import axios from 'axios'
import { ActionItemResult } from '@/types/action'
import { ImageTransform } from '@/types/image'

export default async function fetchImageTransform(id: number) {
  const { data } = await axios.get<ActionItemResult<ImageTransform>>(
    `/api/v1/image-transform/${id}`
  )
  return data
}
