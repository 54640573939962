import { ActionItemResult } from '@/types/action'
import { Author } from '@/types/author'
import axios from 'axios'

export default async function fetchAuthor({
  id,
  withItem,
}: {
  id: number
  withItem: string
}): Promise<ActionItemResult<Author>> {
  const { data } = await axios.get(`/api/v1/author/${id}`, {
    params: {
      with: withItem,
    },
  })
  return data
}
