import { ActionItemResult } from '@/types/action'
import { RecipeCompilation } from '@/types/recipe'
import axios from 'axios'

export default async function updateRecipeCompilation(
  recipeCompilation: Partial<RecipeCompilation>
): Promise<ActionItemResult<RecipeCompilation>> {
  const { data } = await axios.put(
    `/api/v1/recipe-compilation/${recipeCompilation.id}/`,
    recipeCompilation
  )
  return data
}
