import { ActionItemResult } from '@/types/action'
import { Star } from '@/types/star'
import axios from 'axios'

export default async function createStar(
  payload: Pick<Star, 'cyr_name' | 'code' | 'active'>
): Promise<ActionItemResult<Star>> {
  const { data } = await axios.post('/api/v1/star', payload)

  return data
}
