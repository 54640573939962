import axios from 'axios'

export default async function moveRichHtmlBlock({
  blockId,
  afterId,
}: {
  blockId: number
  afterId: number | null
}) {
  await axios.post(`/api/v1/rich-html-blocks/move/${blockId}`, {
    after_id: afterId,
  })
}
