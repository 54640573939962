import axios from 'axios'
import { ActionListPayload, ActionListResult } from '@/types/action'
import { Tag } from '@/types/tag'

export default async function fetchBrands(
  params: ActionListPayload
): Promise<ActionListResult<Tag>> {
  const { data } = await axios.get(`/api/v1/brand/dic/`, {
    params,
  })
  return data
}
