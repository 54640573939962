import { MutationTree } from 'vuex'
import { UserState } from '../types'
import setUserState from './setUserState'
import setShowNotificationMobile from './setShowNotificationMobile'

const mutations: MutationTree<UserState> = {
  setUserState,
  setShowNotificationMobile,
}

export default mutations
