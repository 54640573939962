import { Result, ResultCreated } from '@/types/test'
import axios from 'axios'

export default async function editTestResult({
  id,
  item,
}: {
  id: number
  item: Result | ResultCreated
}) {
  await axios.put(`/api/v1/test-result/${id}`, { ...item })
}
