import axios from 'axios'
import { AuthInfo } from '@/types/auth'

export default async function login(payload: {
  password: string
  email: string
}): Promise<AuthInfo> {
  const { data } = await axios.post('/api/v1/login/', payload, {
    autoLogout: false,
    useRetry: false,
    withAuthorizationHeader: false,
    authHostAsBaseUrl: true,
  })

  return data
}
