import axios from 'axios'

export default async function fetchArticleUrl({
  id,
}: {
  id: number
}): Promise<string> {
  const { data: url } = await axios.get<string>(
    `/api/v1/article/canonical/${id}/`
  )

  const [path] = url.split('?')

  return path
}
