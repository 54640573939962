import { ActionListPayload, ActionListResult } from '@/types/action'
import { Dish } from '@/types/recipe'
import axios from 'axios'

export default async function fetchRecipeDishes({
  cancelToken,
  withItem,
  ...params
}: ActionListPayload): Promise<ActionListResult<Dish>> {
  const { data } = await axios.get(`/api/v1/recipe-dish/`, {
    cancelToken,
    params: {
      ...params,
      with: withItem,
    },
  })
  return data
}
