import { getStorageItemWithExpiry } from '@/helpers/localStorageHelpers'
import { AuthInfo } from '@/types/auth'
import { AuthState } from './types'

const authInfo = getStorageItemWithExpiry<AuthInfo>('authInfo')

const state = (): AuthState => ({
  userId: authInfo?.id ?? 0,
  token: authInfo?.token ?? '',
  permissions: Object.freeze(authInfo?.permissions ?? null),
})

export default state
