import axios from 'axios'
import { PodcastDistributions } from '@/types/podcast'

export default async function changePodcastDistribution({
  id,
  ...payload
}: {
  id: number
  url: string
}): Promise<{ item: PodcastDistributions }> {
  const { data } = await axios.put<{ item: PodcastDistributions }>(
    `/api/v1/podcast-distribution/${id}`,
    payload
  )
  return data
}
