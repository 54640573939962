import axios from 'axios'
import { ActionListPayload, ActionListResult } from '@/types/action'
import { ChangeHistoryItem } from '@/types/changeHistory'
import { ModelId } from '@/types/api'

export default async function fetchChangeHistory({
  model_id,
  item_id,
  ...params
}: Pick<ActionListPayload, 'page' | 'count'> & {
  model_id: ModelId
  item_id: number
}): Promise<ActionListResult<ChangeHistoryItem>> {
  const { data } = await axios.get(
    `/api/v1/change-log/${model_id}/${item_id}`,
    {
      params,
    }
  )

  return data
}
