import { DetailEpisode } from '@/types/episode'
import axios from 'axios'

export default async function fetchEpisode(
  id: number
): Promise<{ item: DetailEpisode }> {
  const { data: item } = await axios.get<{ item: DetailEpisode }>(
    `/api/v1/podcast-episode/${id}`
  )

  return item
}
