import axios from 'axios'
import { File } from '@/types/file'

export default async function fetchFilesList({
  model,
  itemId,
}: {
  model: string
  itemId: number
}): Promise<File[]> {
  const {
    data: { items },
  } = await axios.get(`/api/v1/file/${model}/${itemId}/`)

  return items
}
