import { Contest } from '@/types/contest'
import { FixedContest } from '@/types/fixed'
import axios from 'axios'

export default async function updateContest({
  id,
  data,
}: {
  id: number
  data: Partial<Contest | FixedContest>
}) {
  await axios.put(`/api/v1/contest/${id}`, data)
}
