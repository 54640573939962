import { RootState } from '@/store/types'
import { GetterTree } from 'vuex'
import { PodcastState } from '../types'
import podcastName from './podcastName'

const getters: GetterTree<PodcastState, RootState> = {
  podcastName,
}

export default getters
