import { Mutation } from 'vuex'
import { SocialDistributionState } from '../types'

const setSocialDistributionLoading: Mutation<SocialDistributionState> = (
  state,
  payload
) => {
  state.items.loading = payload
}

export default setSocialDistributionLoading
