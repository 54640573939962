import { Mutation } from 'vuex'
import { UserState } from '../types'

const setShowNotificationMobile: Mutation<UserState> = (
  state,
  payload: boolean
) => {
  state.showNotificationMobile = payload
}

export default setShowNotificationMobile
