import { ActionItemResult } from '@/types/action'
import { ArticleBlock } from '@/types/article'
import axios from 'axios'

export default async function fetchBlock(
  id: number
): Promise<ActionItemResult<ArticleBlock>> {
  const { data } = await axios.get(`/api/v1/rich-html-blocks/${id}/`)

  return data
}
