import { MailingArticle } from '@/types/mailing'
import axios from 'axios'

export default async function updateMailingArticle({
  id,
  ...payload
}: Pick<MailingArticle, 'id' | 'name'> &
  Partial<
    Pick<MailingArticle, 'sort' | 'description' | 'button_caption'>
  >): Promise<void> {
  await axios.put(`/api/v1/mailing-article/${id}`, payload)
}
