import Tracker from '@openreplay/tracker'
import { ENABLED_OPENREPLAY } from '@/config/base'
import { PluginObject } from 'vue'

export const OpenreplayTracker = ENABLED_OPENREPLAY
  ? new Tracker({
      projectKey: '4HGuipFZyM3pwMbdtrNM',
      ingestPoint: 'https://openreplay.fppressa.ru/ingest',
      obscureTextEmails: false,
      obscureTextNumbers: false,
      obscureInputEmails: false,
      defaultInputMode: 0,
    })
  : null

export const OpenreplayPlugin: PluginObject<undefined> = {
  install() {
    if (ENABLED_OPENREPLAY) {
      OpenreplayTracker?.start()
    }
  },
}
