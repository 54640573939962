import { ActionItemResult } from '@/types/action'
import { Photo } from '@/types/photo'
import axios from 'axios'

export default async function createPhoto(
  formData: FormData
): Promise<ActionItemResult<Photo>> {
  const { data } = await axios.post('/api/v1/image/', formData)
  return data
}
