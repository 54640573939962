import { Action } from 'vuex'
import SiriusAPI from '@/api'
import { RootState } from '@/store/types'
import { SocialDistributionState } from '../types'
import { SocialDistribution, SocialGroup } from '@/types/socialDistribution'
import { ActionGroupedListResult } from '@/types/action'

let activeRequest: Promise<
  ActionGroupedListResult<
    Record<string, SocialDistribution[]>,
    SocialDistribution,
    { channels: SocialGroup[] }
  >
> | null = null

const fetchSocialChannels: Action<SocialDistributionState, RootState> = async ({
  commit,
}) => {
  if (activeRequest) {
    return activeRequest
  }

  commit('setSocialDistributionLoading', true)

  try {
    activeRequest = SiriusAPI.socialDistribution.fetchSocialChannels()

    const { meta } = await activeRequest

    commit('setGroups', meta?.fields?.channels || {})
  } catch (error) {
    commit('setGroups', {})

    console.error(error)
  } finally {
    commit('setSocialDistributionLoading', false)
    activeRequest = null
  }
}

export default fetchSocialChannels
