import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { SocialDistributionState } from './types'
import state from './state'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const module: Module<SocialDistributionState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}

export default module
