import { Action } from 'vuex'
import { RootState } from '@/store/types'
import { ArticleState } from '../types'
import { ProjectID } from '@/types/platform'

const deleteActiveUserFromArticle: Action<ArticleState, RootState> = (
  { rootGetters },
  { articleId, userId, platform }
) => {
  const projectId = ProjectID[platform]
  const token = rootGetters['auth/getToken']

  const data = {
    headers: {
      'X-Project-ID': projectId,
      Authorization: `Bearer ${token}`,
    },
  }

  const blob = new Blob([JSON.stringify(data)], { type: 'application/json' })

  return navigator.sendBeacon(
    `/api/v1/article/active-user/${articleId}?userId=${userId}`,
    blob
  )
}

export default deleteActiveUserFromArticle
