import { Mutation } from 'vuex'
import nestedFreeze from '@/helpers/nestedFreeze'
import { SocialDistributionState } from '../types'

const setSocialDistributionItems: Mutation<SocialDistributionState> = (
  state,
  payload
) => {
  state.items.value = nestedFreeze(payload)
}

export default setSocialDistributionItems
