import { ActionItemResult, ActionListPayload } from '@/types/action'
import { AdSiteSection } from '@/types/advSwitch'
import axios from 'axios'

export default async function updateAdSiteSection({
  id,
  ...payload
}: ActionListPayload): Promise<ActionItemResult<AdSiteSection>> {
  const { data } = await axios.put(`/api/v1/ad-site-section/${id}`, payload)

  return data
}
