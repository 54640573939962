import { ActionItemResult } from '@/types/action'
import { Gallery } from '@/types/gallery'
import axios from 'axios'

export default async function fetchGallery({
  id,
  withItem,
}: {
  id: number
  withItem: string
}): Promise<ActionItemResult<Gallery>> {
  const { data } = await axios.get(`/api/v1/gallery/${id}`, {
    params: { with: withItem },
  })

  return data
}
