import axios from 'axios'
import { Article } from '@/types/article'
import { ActionItemResult } from '@/types/action'
import { formatDateToMoscowTime } from '@/lib/date'

export default async function createWordArticle(
  payload: FormData
): Promise<ActionItemResult<Article>> {
  const {
    data: {
      item: { id },
    },
  } = await axios.post<ActionItemResult<Article>>(
    '/api/v1/msword/upload',
    payload
  )

  const { data } = await axios.put(
    `/api/v1/article/${id}/`,
    {
      active_from: formatDateToMoscowTime(new Date(), 'yyyy-MM-dd HH:mm:ss'),
    },
    {
      useWS: {
        model: 'article',
        itemId: id,
      },
    }
  )

  return data
}
