import { Getter } from 'vuex'
import { UserGetters, UserState } from '../types'
import { RootState } from '@/store/types'
import { ProjectID } from '@/types/platform'

const getUserId: Getter<UserState, RootState> = (state, getters, rootState) => {
  const getter: UserGetters['getUserId'] = (
    projectId: ProjectID = ProjectID[rootState.platform]
  ) => {
    if (projectId === ProjectID.im) {
      return state.current?.id || 0
    }

    return state.current?.projectAccessUsers[projectId]?.id || 0
  }

  return getter
}

export default getUserId
