import { ActionItemResult } from '@/types/action'
import { Contest } from '@/types/contest'
import axios from 'axios'

export default async function fetchContest({
  id,
  withItem,
}: {
  id: number
  withItem?: string
}): Promise<ActionItemResult<Contest>> {
  const { data } = await axios.get(`/api/v1/contest/${id}`, {
    params: {
      with: withItem,
    },
  })
  return data
}
