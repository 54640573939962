import { ActionListPayload, ActionListResult } from '@/types/action'
import { PlanCompare } from '@/types/planCompare'
import axios from 'axios'

export default async function fetchComparePlans({
  page,
  count,
  withItem,
  cancelToken,
  filter,
  sort,
  select,
  useCache,
}: ActionListPayload): Promise<ActionListResult<PlanCompare>> {
  const { data } = await axios.get('/api/v1/plan-compare/', {
    cancelToken,
    params: {
      page,
      count,
      with: withItem,
      filter,
      sort,
      select,
      useCache,
    },
  })

  return data
}
