import { Data } from '@/types/section'
import axios from 'axios'

export default async function updateSection({
  id,
  data,
}: {
  id: number
  data: Data
}) {
  await axios.put(`/api/v1/section/${id}`, data)
}
