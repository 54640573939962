import axios from 'axios'

export default async function updateContact({
  id,
  title,
  phone,
  address,
}: {
  id: number
  title: string
  phone: string
  address: string
}) {
  const { data } = await axios.put(`/api/v1/contacts/${id}/`, {
    title,
    phone,
    address,
  })

  return data
}
