import axios from 'axios'
import { ActionListPayload } from '@/types/action'
import { Work } from '@/types/biograhies'

export default async function createStarWorksSingle(
  payload: ActionListPayload
): Promise<Work> {
  const {
    data: { item },
  } = await axios.post('/api/v1/star-work/', payload)

  return item
}
