import { ActionListPayload, ActionListResult } from '@/types/action'
import { Feature } from '@/types/feature'
import axios from 'axios'

export default async function fetchFeatures({
  filter,
  page,
  withItem,
  count,
  sort,
  cancelToken,
}: ActionListPayload): Promise<ActionListResult<Feature>> {
  const { data } = await axios.get('/api/v1/feature-article/', {
    cancelToken,
    params: {
      page,
      sort,
      count,
      filter,
      with: withItem,
    },
  })
  return data
}
