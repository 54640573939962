import Vue, { PluginObject } from 'vue'
import events from './events'
import { NotificationOptions } from './types'

import Notifications from './components/Notifications/Notifications.vue'

export const NotificationsPlugin: PluginObject<undefined> = {
  install() {
    let notificationId = 1

    Vue.prototype.$notify = (params: NotificationOptions) => {
      const id = params.id || notificationId++

      events.$emit('add', { ...params, id })

      return { id }
    }

    Vue.prototype.$notifyClose = (params: { id: number }) => {
      events.$emit('remove', params)
    }

    Vue.component('Notifications', Notifications)
  },
}
