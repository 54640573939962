import { ActionItemResult } from '@/types/action'
import { RecipeProduct } from '@/types/recipe'
import axios from 'axios'

export default async function updateRecipeProduct({
  id,
  ...payload
}: Pick<RecipeProduct, 'id'> & Partial<RecipeProduct>): Promise<
  ActionItemResult<RecipeProduct>
> {
  const { data } = await axios.put(`/api/v1/recipe-product/${id}/`, payload)
  return data
}
