import axios from 'axios'
import { NewsBulletinItem } from '@/types/newsBulletin'
import { ActionListResult, ActionListPayload } from '@/types/action'

export default async function fetchNewsBulletinItems(
  payload: ActionListPayload
) {
  const { token, date, count, page } = payload
  const { data } = await axios
    .create({
      baseURL: 'https://parser.fppressa.ru',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .get<ActionListResult<NewsBulletinItem>>(
      `/api/v1/liveinternet-pages/${date}`,
      {
        params: {
          count,
          page,
        },
      }
    )

  return data
}
