import axios from 'axios'
import { ActionListPayload, ActionListResult } from '@/types/action'
import { DistributionItem } from '@/types/distribution'

export default async function fetchDistributionList({
  ...params
}: ActionListPayload): Promise<ActionListResult<DistributionItem>> {
  const { data } = await axios.get(`/api/v1/social-distribution/plan/`, {
    params,
  })
  return data
}
