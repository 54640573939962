import { ActionListPayload, ActionListResult } from '@/types/action'
import { RecipeCompilation } from '@/types/recipe'
import axios from 'axios'

export default async function fetchRecipeCompilations({
  filter,
  withItem,
  count,
  sort,
  cancelToken,
}: ActionListPayload): Promise<ActionListResult<RecipeCompilation>> {
  const { data } = await axios.get('/api/v1/recipe-compilation', {
    cancelToken,
    params: {
      sort,
      count,
      filter,
      with: withItem,
    },
  })
  return data
}
