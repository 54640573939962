import { Getter } from 'vuex'
import { RootState } from '@/store/types'
import { ArticleGetters, ArticleState } from '../types'

const articleTypeByCode: Getter<ArticleState, RootState> = (state) => {
  const getter: ArticleGetters['articleTypeByCode'] = (code) =>
    state.fields.types[code]

  return getter
}

export default articleTypeByCode
