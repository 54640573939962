import axios from 'axios'

export default async function fetchStatisticSeo({
  type,
  start,
  end,
  filename,
}: {
  type: 'content' | 'search'
  start: string
  end: string
  filename?: string
}): Promise<Blob> {
  const { data } = await axios.get(
    `/api/v1/statistic/seo/${type}/${start}/${end}/`,
    {
      responseType: 'blob',
      params: {
        filename,
      },
    }
  )

  return data
}
