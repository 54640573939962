import { Getter } from 'vuex'
import { RootState } from '@/store/types'
import { ArticleState } from '../types'
import uniqBy from 'lodash.uniqby'

const articleTypesList: Getter<ArticleState, RootState> = (state) => {
  return uniqBy(Object.values(state.fields.types), 'value')
}

export default articleTypesList
