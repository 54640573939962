import { ActionItemResult } from '@/types/action'
import { AdvTag } from '@/types/advTag'
import axios from 'axios'

export default async function createAdvTag({
  name,
}: {
  name: string
}): Promise<ActionItemResult<AdvTag>> {
  const { data } = await axios.post('/api/v1/comparative-commerce/', { name })
  return data
}
