import axios from 'axios'
import { ArticleBlock } from '@/types/article'
import removeZWS from '@/helpers/removeZWS'

const translateWidgetExpert = (
  item: ArticleBlock
): Pick<ArticleBlock, 'widget'> => {
  if (item.widget?.name !== 'expert') {
    return item
  }

  return {
    ...item,
    widget: {
      name: 'newExpert',
      params: null,
    },
  }
}

export default async function fetchBlocks({
  model,
  id,
}: {
  model: string
  id: number
}): Promise<ArticleBlock[]> {
  const {
    data: { items },
  } = await axios.get<{ items: ArticleBlock[] }>(
    `/api/v1/rich-html-blocks/${model}/${id}/`
  )

  return items.map((item) => ({
    ...translateWidgetExpert(item),
    id: item.id,
    isInoagent: false,
    cid: item.id?.toString(),
    prev_cid: item.prev_id?.toString() || null,
    text: removeZWS(item.text || ''),
  }))
}
