import axios from 'axios'
import { BlockArticle } from '@/types/block'
import { ActionItemResult } from '@/types/action'

export default async function createBlockArticle(
  payload: Partial<BlockArticle> & Partial<Pick<BlockArticle, 'days_limit'>>
): Promise<BlockArticle> {
  const {
    data: { item },
  } = await axios.post<ActionItemResult<BlockArticle>>(
    '/api/v1/block-article/',
    payload
  )

  return item
}
