import { SocialDistributionState } from './types'

const state = (): SocialDistributionState => ({
  groups: {},
  items: {
    loading: false,
    value: [],
  },
  publishedGroup: null,
  options: {
    pasteText: '',
    pasteDate: '',
  },
})

export default state
