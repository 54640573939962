import axios from 'axios'

interface EmbedInfo {
  ratio: number
  imageId: number
  permalink: string
}

export default async function fetchInfoWidget({
  type,
  link,
  id,
}: {
  type: string
  link?: string
  id?: string
}): Promise<EmbedInfo> {
  const { data } = await axios.post(`/api/v1/widget/info/${type}`, {
    id,
    link,
  })
  return data
}
