import axios from 'axios'
import { ActionListPayload, ActionListResult } from '@/types/action'
import { ShortBlockArticle } from '@/types/block'

export default async function fetchArticleScroll(
  type: string,
  params: ActionListPayload
): Promise<ActionListResult<ShortBlockArticle>> {
  const { data } = await axios.get<ActionListResult<ShortBlockArticle>>(
    `/api/v1/block-article/${type}/dashboard/`,
    { params }
  )

  return data
}
