import axios from 'axios'
import { Podcast } from '@/types/podcast'

export default async function updatePodcast(
  payload: Podcast
): Promise<{ item: Podcast }> {
  const { data } = await axios.put<{ item: Podcast }>(
    `/api/v1/podcast/${payload.id}`,
    payload
  )
  return data
}
