import axios from 'axios'

export default async function postComment(payload: {
  model: number
  item_id?: number
  parent_id?: number
  projectId: number
  message: string
  email: string | null
}) {
  const headers: Record<string, string | number> = {}

  const { model, item_id, parent_id, projectId, message, email } = payload

  if (projectId) {
    headers['X-Project-ID'] = projectId
  }

  await axios.post('/api/v1/comment/', {
    model,
    item_id,
    parent_id,
    message,
    email,
    headers,
  })
}
