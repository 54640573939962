import { AdvTagEditData } from '@/types/advTag'
import axios from 'axios'

export default async function editAdvTag({
  id,
  tag,
}: {
  id: number
  tag: AdvTagEditData
}) {
  await axios.put(`/api/v1/comparative-commerce/${id}`, { ...tag })
}
