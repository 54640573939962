import { Getter } from 'vuex'
import { RootState } from '@/store/types'
import { SocialDistributionState } from '../types'

const socialDistributionLoading: Getter<SocialDistributionState, RootState> = (
  state
) => {
  return state.items.loading
}

export default socialDistributionLoading
