import { Mutation } from 'vuex'
import {
  SocialDistribution,
  SocialDistributionStatus,
} from '@/types/socialDistribution'
import { SocialDistributionState } from '../types'

const updateSocialDistribution: Mutation<SocialDistributionState> = (
  state,
  payload: Pick<
    SocialDistribution,
    | 'channel'
    | 'model_id'
    | 'item_id'
    | 'net_name'
    | 'enabled'
    | 'cid'
    | 'status'
  > &
    Partial<
      Omit<SocialDistribution, 'channel' | 'model_id' | 'item_id' | 'net_name'>
    >
) => {
  const newItems = [...state.items.value]

  const itemIndex = newItems.findIndex((item) => {
    return item.cid === payload.cid
  })

  if (itemIndex > -1 && !payload.enabled && !newItems[itemIndex].id) {
    newItems.splice(itemIndex, 1)
  } else if (itemIndex > -1) {
    const item = newItems[itemIndex]

    newItems[itemIndex] = {
      ...item,
      ...payload,
    }
  } else if (
    payload.enabled &&
    payload.status === SocialDistributionStatus.NEW
  ) {
    newItems.unshift({
      id: 0,
      active_from: null,
      republish_from: null,
      text: null,
      image_id: null,
      ...payload,
    })
  }

  state.items.value = newItems
}

export default updateSocialDistribution
