import { RootState } from '@/store/types'
import { GetterTree } from 'vuex'
import { SocialDistributionState } from '../types'
import groups from './groups'
import availableSocials from './availableSocials'
import socialDistributionItems from './socialDistributionItems'
import socialDistributionLoading from './socialDistributionLoading'
import options from './options'
import publishedGroup from './publishedGroup'

const getters: GetterTree<SocialDistributionState, RootState> = {
  groups,
  availableSocials,
  socialDistributionItems,
  socialDistributionLoading,
  options,
  publishedGroup,
}

export default getters
