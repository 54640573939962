import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { SidebarState } from './types'
import state from './state'
import mutations from './mutations'
import getters from './getters'

const module: Module<SidebarState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
}

export default module
