import axios from 'axios'
import { ActionItemResult } from '@/types/action'
import { Fact } from '@/types/fact'

export default async function createFact({
  text,
  groups,
}: {
  text: string
  groups: number[]
}): Promise<ActionItemResult<Fact>> {
  const { data } = await axios.post('/api/v1/fact/', { text, groups })
  return data
}
