import axios from 'axios'
import { ActionResult } from '@/types/action'
import { SocialCode, SocialDistribution } from '@/types/socialDistribution'
import { ModelId } from '@/types/api'

export default async function createSocialDistributionMass(payload: {
  model_id: ModelId
  item_id: number
  text: string
  active_from: string
  enabled: boolean
  channels: Partial<Record<SocialCode, string[]>>
}): Promise<SocialDistribution[]> {
  const {
    data: { items },
  } = await axios.post<ActionResult<SocialDistribution[]>>(
    '/api/v1/social-distribution/mass',
    payload
  )

  return items
}
