import axios from 'axios'
import { ActionListPayload } from '@/types/action'
import { Work } from '@/types/biograhies'

export default async function createStarWorksMass(
  payload: ActionListPayload
): Promise<Work[]> {
  const {
    data: { items },
  } = await axios.post('/api/v1/star-work/mass', payload)

  return items
}
