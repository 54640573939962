import deepFreeze from '@/helpers/deepFreeze'
import { Field } from '@/types/action'
import { Mutation } from 'vuex'
import { ArticleState } from '../types'

const setArticleField: Mutation<ArticleState> = (
  state,
  { key, value }: { key: 'types'; value: Record<string, Field> }
) => {
  state.fields[key] = deepFreeze(value)
}

export default setArticleField
