import { DistributionItem } from '@/types/widget/video'
import axios from 'axios'

export default async function createVideoDistribution(
  payload: DistributionItem & { group_id: string }
) {
  const { data } = await axios.post('/api/v1/video-distribution/', {
    ...payload,
  })
  return data
}
