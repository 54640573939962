import axios from 'axios'
import htmlToText from '@/helpers/htmlToText'
import getWords from '@/helpers/getWords'

export default class SpellCheck {
  constructor({
    editor,
    onInput,
  }: {
    editor: CKEDITOR.editor
    onInput: (value: string) => void
  }) {
    let focusTimeoutId = 0
    let isActivated = false
    let isFocused = false
    let isSettingData = false
    let currentText = ''
    let oldText = ''
    let spellCheckLoading = false
    let spellCheckResult: Record<string, string[]> = {}

    const spellCheckMisspell = () => {
      if (isFocused) return

      const newHTML = getWords(htmlToText(editor.editable().getHtml())).reduce(
        (acc, word) => {
          if (!spellCheckResult[word]) {
            acc += word
          } else {
            acc += `<span class="spell-check-misspell">${word}</span>`
          }

          return acc
        },
        ''
      )

      editor.editable().setHtml(newHTML)
    }

    const spellCheck = async () => {
      if (spellCheckLoading) return

      currentText = htmlToText(editor.getData())

      spellCheckLoading = true

      spellCheckMisspell()

      try {
        if (currentText !== oldText) {
          const { data } = await axios.post<Record<string, string[]>>(
            '/api/v1/spell/',
            {
              text: currentText,
            },
            { useRetry: false }
          )

          spellCheckResult = data
        }

        oldText = currentText
        spellCheckLoading = false
        spellCheckMisspell()
      } catch (error) {
        spellCheckLoading = false
      }
    }

    editor.on('focus', () => {
      isActivated = true

      isFocused = true

      window.clearTimeout(focusTimeoutId)

      focusTimeoutId = window.setTimeout(() => {
        editor.fire('lockSnapshot')

        const bookmark2 = editor.getSelection().createBookmarks(true)

        editor.setData(editor.editable().getHtml(), {
          callback: () => {
            editor.getSelection().selectBookmarks(bookmark2)
            editor.fire('unlockSnapshot')
          },
        })
      }, 30)
    })

    const changeListenner = () => {
      onInput(editor.getData())
    }

    editor.on('setData', () => {
      isSettingData = true
    })

    editor.on('afterSetData', () => {
      isSettingData = false
      spellCheck()
    })

    editor.on('change', () => {
      if (!isActivated || isSettingData) return

      changeListenner()
    })

    editor.on('blur', () => {
      isFocused = false

      window.clearTimeout(focusTimeoutId)

      spellCheck()
    })

    spellCheck()
  }
}
