import axios from 'axios'
import { Article } from '@/types/article'
import { Biograhy } from '@/types/biograhies'

export default async function createBiography(payload: {
  code: string
  name: string
  star?: Partial<Biograhy>
}): Promise<Article> {
  const {
    data: { item },
  } = await axios.post('/api/v1/article/biography', payload)

  return item
}
