import axios from 'axios'
import { Podcast } from '@/types/podcast'
import { ActionListPayload } from '@/types/action'

export default async function fetchPodcasts({
  cancelToken,
  ...params
}: ActionListPayload): Promise<{
  items: Podcast[]
  meta: { total_count: number }
}> {
  const { data } = await axios.get<{
    items: Podcast[]
    meta: { total_count: number }
  }>('/api/v1/podcast/', {
    params,
    cancelToken,
  })
  return data
}
