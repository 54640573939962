import axios from 'axios'

import BrandWidget from '@/types/widget/brandWidget'
import { ActionItemResult } from '@/types/action'

export default async function updateBrandWidget({
  id,
  ...payload
}: BrandWidget) {
  const { data } = await axios.put<ActionItemResult<BrandWidget>>(
    `/api/v1/brand-widget/${id}`,
    payload
  )
  return data
}
