import { ActionItemResult } from '@/types/action'
import { TechnicalForm, TechnicalArticleItem } from '@/types/technical'
import axios from 'axios'

export default async function createTechnicalArticle({
  formFields,
}: {
  formFields: Pick<TechnicalForm, 'title'>
}): Promise<ActionItemResult<TechnicalArticleItem>> {
  const { data } = await axios.post('/api/v1/technical/', formFields)
  return data
}
