import { ActionListPayload, ActionListResult } from '@/types/action'
import { TechnicalArticleItem } from '@/types/technical'
import axios from 'axios'

export default async function fetchTechnicalArticles({
  filter,
  sort,
  cancelToken,
}: ActionListPayload): Promise<ActionListResult<TechnicalArticleItem>> {
  const { data } = await axios.get('/api/v1/technical/', {
    cancelToken,
    params: { filter, sort },
  })
  return data
}
