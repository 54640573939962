import { Getter } from 'vuex'
import { UserGetters, UserState } from '../types'
import { RootState } from '@/store/types'
import { Role } from '@/types/role'

const userIsAdmin: Getter<UserState, RootState> = (state, getters) => {
  const getProjectAccessUserRoles = getters[
    'getProjectAccessUserRoles'
  ] as UserGetters['getProjectAccessUserRoles']

  return getProjectAccessUserRoles().some((userRole) =>
    [Role.ADMIN].includes(userRole)
  )
}

export default userIsAdmin
