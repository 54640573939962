import axios from 'axios'
import { ActionListPayload, ActionListResult } from '@/types/action'
import { MailingListItem } from '@/types/mailing'

export default async function fetchMailingList({
  cancelToken,
  ...params
}: ActionListPayload): Promise<ActionListResult<MailingListItem>> {
  const { data } = await axios.get('/api/v1/mailing/', {
    cancelToken,
    params,
  })

  return data
}
