import { ActionItemResult } from '@/types/action'
import { RecipeIngredient } from '@/types/recipe'
import axios from 'axios'

export default async function updateRecipeIngredientNorm({
  id,
  ...payload
}: Pick<RecipeIngredient, 'id'> &
  Partial<Omit<RecipeIngredient, 'id'>>): Promise<
  ActionItemResult<RecipeIngredient>
> {
  const { data } = await axios.put(
    `/api/v1/recipe-ingredient-norm/${id}`,
    payload
  )

  return data
}
