import { ActionListPayload, ActionListResult } from '@/types/action'
import { Author } from '@/types/author'
import axios from 'axios'

export default async function fetchAuthors({
  withItem,
  cancelToken,
  projectId,
  ...params
}: ActionListPayload): Promise<ActionListResult<Author>> {
  const headers: Record<string, string | number> = {}

  if (projectId) {
    headers['X-Project-ID'] = projectId
  }

  const { data } = await axios.get('/api/v1/author/', {
    cancelToken,
    headers,
    params: {
      ...params,
      with: withItem,
    },
  })
  return data
}
