import axios from 'axios'

export default async function changePassword(payload: {
  password: string
  code: string
}): Promise<any> {
  const { data } = await axios.post('/api/v1/change-password/', payload, {
    autoLogout: false,
    useRetry: false,
    withAuthorizationHeader: false,
    authHostAsBaseUrl: true,
  })

  return data
}
