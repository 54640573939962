import { ActionItemResult } from '@/types/action'
import { Section } from '@/types/section'
import axios from 'axios'

export default async function createSection({
  name,
  parent_id,
}: {
  name: string
  parent_id: number | null
}): Promise<ActionItemResult<Section>> {
  const { data } = await axios.post('/api/v1/section/', { name, parent_id })
  return data
}
