import axios from 'axios'
import { Article, ArticleForm } from '@/types/article'
import { ActionItemResult } from '@/types/action'
import { formatDateToMoscowTime, dateToMoscowTime } from '@/lib/date'

export default async function createArticle({
  formFields,
}: {
  formFields: ArticleForm
}): Promise<ActionItemResult<Article>> {
  const { data } = await axios.post(
    '/api/v1/article/',
    {
      ...formFields,
      active_from: formatDateToMoscowTime(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      order_date: dateToMoscowTime(new Date()).toISOString(),
    },
    {
      useRetry: false,
    }
  )

  return data
}
