import axios, { CancelToken } from 'axios'

export default async function fetchStatistics<T>({
  period,
  dataType,
  specific = '',
  periodPublished,
  cancelToken,
  count,
  id,
  useCache,
}: {
  period: string
  dataType: string
  specific?: string
  periodPublished?: string
  count?: number
  cancelToken?: CancelToken
  id?: number
  useCache?: number
}): Promise<T> {
  const url = [
    '/api/v1/statistic/clickhouse',
    period,
    periodPublished,
    dataType,
    specific,
    id,
  ]
    .filter((part) => !!part?.toString().length)
    .join('/')

  const { data } = await axios.get(url, {
    cancelToken,
    params: {
      count,
      useCache,
    },
  })
  return data
}
