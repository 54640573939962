import deepFreeze from '@/helpers/deepFreeze'
import { Sidebar } from '@/types/sidebar'
import { Mutation } from 'vuex'
import { SidebarState, SidebarStatus } from '../types'

const toggleSidebar: Mutation<SidebarState> = (
  state,
  payload: { sidebar: Sidebar; status: SidebarStatus; value: boolean }
) => {
  state[payload.status] = deepFreeze(
    state[payload.status]
      .filter((current) => current.type !== payload.sidebar.type)
      .concat(payload.value ? [payload.sidebar] : [])
  )
}

export default toggleSidebar
