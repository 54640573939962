import { ActionItemResult } from '@/types/action'
import { Fixed } from '@/types/fixed'
import axios from 'axios'

export default async function createFixedArticle(payload: {
  id: number
  section_id: number
  position?: number
}): Promise<ActionItemResult<Fixed>> {
  const { data } = await axios.post('/api/v1/fixed/fixed-article/add', payload)
  return data
}
