import { ActionItemResult } from '@/types/action'
import { RecipeProduct } from '@/types/recipe'
import axios from 'axios'

export default async function createRecipeProduct(
  payload: Partial<RecipeProduct>
): Promise<ActionItemResult<RecipeProduct>> {
  const { data } = await axios.post('/api/v1/recipe-product/', payload)
  return data
}
