import axios from 'axios'

interface EmbedInfo {
  ratio: number
  imageId: number
  permalink: string
}

export default async function addImageFake({
  type,
  image,
  name,
  label,
}: {
  type: string
  header?: string
  text?: string
  image?: string
  label?: string
  name?: string
}): Promise<EmbedInfo> {
  const { data } = await axios.post(
    `/api/v1/image-fake?type=${type}&image=${image}&name=${name}&label=${label}&project=cosmo`,
    null,
    {
      useRetry: false,
    }
  )

  return data
}
