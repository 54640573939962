import axios from 'axios'
import { ActionItemResult } from '@/types/action'
import { MailingDetailItem } from '@/types/mailing'

export default async function createMailingItem(
  payload: Partial<
    Pick<
      MailingDetailItem,
      | 'name'
      | 'pre_header'
      | 'status'
      | 'active'
      | 'date_from'
      | 'date_to'
      | 'dispatch_date'
      | 'interest_id'
      | 'custom_subject'
      | 'segment_external_id'
      | 'custom_button_caption'
      | 'custom_button_url'
      | 'custom_description'
      | 'custom_image_id'
    >
  >
): Promise<ActionItemResult<MailingDetailItem>> {
  const { data } = await axios.post<ActionItemResult<MailingDetailItem>>(
    '/api/v1/mailing/',
    payload
  )

  return data
}
