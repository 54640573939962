import { ActionItemResult } from '@/types/action'
import { ImageTransform } from '@/types/image'
import axios from 'axios'

export default async function fetchImageTransform(
  imageId: number
): Promise<ActionItemResult<ImageTransform>> {
  const { data } = await axios.get(`/api/v1/image-transform/${imageId}`)

  return data
}
