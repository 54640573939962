import { ActionItemResult } from '@/types/action'
import { RecipeCompilation } from '@/types/recipe'
import axios from 'axios'

export default async function fetchRecipeCompilation({
  id,
  withItem,
}: {
  id: number
  withItem: string
}): Promise<ActionItemResult<RecipeCompilation>> {
  const { data } = await axios.get(`/api/v1/recipe-compilation/${id}/`, {
    params: {
      with: withItem,
    },
  })
  return data
}
