import { PollEditData, PollActiveField } from '@/types/poll'
import axios from 'axios'

export default async function editPoll({
  id,
  poll,
}: {
  id: number
  poll?: PollEditData | PollActiveField
}) {
  await axios.put(`/api/v1/poll/${id}/`, {
    ...poll,
  })
}
