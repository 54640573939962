import { ActionItemResult } from '@/types/action'
import { Gallery } from '@/types/gallery'
import axios from 'axios'

export default async function createGallery({
  name,
  horizontal,
  active,
}: {
  name: string
  horizontal: number
  active: 0 | 1
}): Promise<ActionItemResult<Gallery>> {
  const { data } = await axios.post('/api/v1/gallery/', {
    name,
    horizontal,
    active,
  })
  return data
}
