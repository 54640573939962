import { GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { VideoWidgetState } from '../types'

import getProgress from './getProgress'

const getters: GetterTree<VideoWidgetState, RootState> = {
  getProgress,
}

export default getters
