import axios from 'axios'

export default async function createContactDepartment({
  code,
  title,
  contacts_id,
  new_issue,
}: {
  code: string
  title: string
  contacts_id: number
  new_issue: number
}) {
  const { data } = await axios.post(
    '/api/v1/contacts-department/',
    {
      code,
      title,
      new_issue,
      contacts_id,
    },
    {
      useRetry: false,
    }
  )

  return data
}
