import { ImageTransformMeta } from '@/types/image'
import axios from 'axios'

export default async function fetchConstructorMeta(): Promise<ImageTransformMeta> {
  const {
    data: { meta },
  } = await axios.get(`/api/v1/image-transform/meta`)

  return meta
}
