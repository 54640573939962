import axios from 'axios'
import { ActionItemResult } from '@/types/action'
import { Image } from '@/types/image'

export default async function fetchImage({
  id,
}: {
  id: number
}): Promise<ActionItemResult<Image>> {
  const { data } = await axios.get(`/api/v1/image/${id}/`)

  return data
}
