import { ActionListPayload, ActionListResult } from '@/types/action'
import { Video } from '@/types/widget/video'
import axios from 'axios'

export default async function fetchVideoWidgets({
  filter,
  page,
  withItem,
  count,
  sort,
  cancelToken,
}: ActionListPayload): Promise<ActionListResult<Video>> {
  const { data } = await axios.get('/api/v1/video/', {
    cancelToken,
    params: {
      page,
      sort,
      count,
      filter,
      with: withItem,
    },
  })
  return data
}
