import { ActionItemResult } from '@/types/action'
import { Expert } from '@/types/expert'
import axios, { CancelToken } from 'axios'

export default async function fetchExpert({
  id,
  withItem,
  cancelToken,
  ...params
}: {
  id: number
  withItem?: string
  cancelToken?: CancelToken
}): Promise<ActionItemResult<Expert>> {
  const { data } = await axios.get(`/api/v1/expert/${id}/`, {
    cancelToken,
    params: {
      ...params,
      with: withItem,
    },
  })
  return data
}
