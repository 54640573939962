import axios from 'axios'
import { ActionItemResult } from '@/types/action'
import { Issue } from '@/types/issue'

export default async function createIssue({
  name,
}: {
  name: string
}): Promise<ActionItemResult<Issue>> {
  const { data } = await axios.post('/api/v1/issue/', { name })
  return data
}
