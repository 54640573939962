import { ActionItemResult } from '@/types/action'
import { Issue } from '@/types/issue'
import axios from 'axios'

export default async function fetchIssue(
  id: number
): Promise<ActionItemResult<Issue>> {
  const { data } = await axios.get(`/api/v1/issue/${id}`)
  return data
}
