import { ActionItemResult } from '@/types/action'
import { RecipeStep } from '@/types/recipe'
import axios from 'axios'

export default async function createRecipeStep(
  payload: Partial<RecipeStep>
): Promise<ActionItemResult<RecipeStep>> {
  const { data } = await axios.post('/api/v1/recipe-step/', payload)
  return data
}
