import axios, { CancelToken } from 'axios'

export default async function publishArticle({
  id,
  cancelToken,
}: {
  id: number
  cancelToken?: CancelToken
}) {
  return axios.post(`/api/v1/article/publish/${id}`, null, {
    cancelToken,
    useRetry: false,
    useWS: {
      model: 'article',
      itemId: id,
    },
  })
}
