import { User } from '@/types/user'
import axios from 'axios'

export default async function fetchProfile(): Promise<User> {
  const {
    data: { item },
  } = await axios.get('/api/v1/profile/', {
    useRetry: false,
    autoLogout: true,
  })

  return item
}
