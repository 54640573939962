import { ActionListPayload, ActionListResult } from '@/types/action'
import { EfficientTitle } from '@/types/article'
import axios from 'axios'

export default async function fetchArticleEfficientTitles({
  withItem,
  cancelToken,
  ...params
}: ActionListPayload): Promise<ActionListResult<EfficientTitle>> {
  const { data } = await axios.get('/api/v1/article-efficient-title/', {
    cancelToken,
    params: {
      ...params,
      with: withItem,
    },
  })

  return data
}
