import { ActionItemResult } from '@/types/action'
import { Fact } from '@/types/fact'
import axios from 'axios'

export default async function fetchFact({
  id,
  withItem,
}: {
  id: number
  withItem: string
}): Promise<ActionItemResult<Fact>> {
  const { data } = await axios.get(`/api/v1/fact/${id}`, {
    params: {
      with: withItem,
    },
  })
  return data
}
