import axios, { CancelToken } from 'axios'

export default async function fetchVerstkaIOArticleLinks({
  id,
  cancelToken,
}: {
  id: number
  cancelToken?: CancelToken
}) {
  const { data } = await axios.get<{
    mobile: string
    desktop: string
  }>(`/api/v1/article/verstka-io-links/${id}/`, {
    autoLogout: false,
    cancelToken,
  })

  return data
}
