import { ActionItemResult } from '@/types/action'
import { VideoPartner } from '@/types/widget/videoPartner'
import axios from 'axios'

export default async function createVideoPartner({
  name,
}: {
  name: string
}): Promise<ActionItemResult<VideoPartner>> {
  const { data } = await axios.post('/api/v1/video-partner/', {
    name,
  })
  return data
}
