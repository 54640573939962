import { ActionItemResult } from '@/types/action'
import { EmbedWidgetParams } from '@/types/articleWidgets'
import axios from 'axios'

export default async function createVideoByCode({
  code,
  orientation,
}: {
  code: string
  orientation?: number | null
}): Promise<ActionItemResult<EmbedWidgetParams>> {
  const { data } = await axios.post('/api/v1/video/create-by-code', {
    code,
    orientation,
  })
  return data
}
