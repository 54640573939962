import axios from 'axios'

export default async function createContactPerson({
  role,
  full_name,
  email,
  phone,
  department_id,
}: {
  role: string
  full_name: string
  email: string
  phone: string
  department_id: number
}) {
  const {
    data: { items },
  } = await axios.post(
    `/api/v1/contacts-person/`,
    {
      role,
      full_name,
      email,
      phone,
      department_id,
    },
    {
      useRetry: false,
    }
  )

  return items
}
