import axios from 'axios'
import { ActionItemResult } from '@/types/action'
import htmlToText from '@/helpers/htmlToText'
import { SocialDistribution } from '@/types/socialDistribution'

export default async function updateSocialDistribution({
  id,
  text,
  ...payload
}: Partial<Omit<SocialDistribution, 'id'>> &
  Pick<SocialDistribution, 'id'>): Promise<SocialDistribution> {
  const {
    data: { item },
  } = await axios.put<ActionItemResult<SocialDistribution>>(
    `/api/v1/social-distribution/${id}`,
    {
      ...payload,
      text: text && htmlToText(text),
    }
  )

  return item
}
