import { ActionItemResult } from '@/types/action'
import { Expert } from '@/types/expert'
import axios from 'axios'

export default async function updateExpert({
  id,
  ...payload
}: Pick<Expert, 'id'> & Partial<Omit<Expert, 'id'>>): Promise<
  ActionItemResult<Expert>
> {
  const { data } = await axios.put(`/api/v1/expert/${id}/`, payload)

  return data
}
