import { Fixed, Article } from '@/types/fixed'
import axios, { CancelToken } from 'axios'

export default async function fetchFixeds({
  cancelToken,
  ...params
}: {
  cancelToken?: CancelToken
  type?: string
  target?: number
} = {}): Promise<
  Omit<Fixed, 'fixedArticles' | 'pinnedAtSection'> & {
    fixedArticles?: {
      articles: Article[]
      section: {
        id: number
        name: string
        parent_id: number | null
      }
    }[]
    pinnedAtSection?: {
      articles: Article[]
      section: {
        id: number
        name: string
        parent_id: number | null
      }
    }[]
  }
> {
  const { data } = await axios.get('/api/v1/fixed/', {
    cancelToken,
    params,
  })
  return data
}
