import { Location } from 'vue-router'

export enum Platform {
  im = 'im',
  bazaar = 'bazaar',
  cosmo = 'cosmo',
  esquire = 'esquire',
  goodhouse = 'goodhouse',
  grazia = 'grazia',
  mhealth = 'mhealth',
  popmech = 'popmech',
  robb = 'robb',
  girlspower = 'girlspower',
}

export enum PlatformTitle {
  im = 'Sirius CMS',
  cosmo = 'The Voice',
  goodhouse = 'Новый очаг',
  popmech = 'TechInsider',
  grazia = 'Grazia',
  bazaar = `The Symbol`,
  mhealth = `Men Today`,
  esquire = 'Правила жизни',
  robb = 'RobbReport',
  girlspower = 'Idolmedia',
}

export enum PlatformShortTitle {
  im = 'Sirius CMS',
  cosmo = 'Voice',
  goodhouse = 'Новый очаг',
  popmech = 'TechInsider',
  grazia = 'Grazia',
  bazaar = `Symbol`,
  mhealth = `Men Today`,
  esquire = 'Правила жизни',
  robb = 'RobbReport',
  girlspower = 'Idolmedia',
}

export interface PlatformParams {
  title: string
  shortTitle: string
  slug: Platform
  color: string
  borderColor: string
  background: string
  backgroundNav: string
  fullTitle: string
  fullTitleColor: string
  origin: string
  projectId: ProjectID
  counter: string
  to: Location
}

export enum ProjectID {
  im = 0,
  cosmo = 1,
  goodhouse = 2,
  popmech = 3,
  grazia = 4,
  bazaar = 5,
  mhealth = 6,
  esquire = 7,
  robb = 8,
  girlspower = 9,
}

export enum PlatformOrigin {
  im = 'https://admin.imweb.ru',
  bazaar = 'https://www.thesymbol.ru',
  cosmo = 'https://www.thevoicemag.ru',
  esquire = 'https://www.pravilamag.ru',
  goodhouse = 'https://www.novochag.ru',
  grazia = 'https://www.grazia.ru',
  mhealth = 'https://www.mentoday.ru',
  popmech = 'https://www.techinsider.ru',
  robb = 'https://robb.report',
  girlspower = 'https://www.idolmedia.ru',
}

export enum CountersConfig {
  im = '',
  cosmo = '25862525',
  bazaar = '32921788',
  robb = '45018163',
  popmech = '19745680',
  mhealth = '24207301',
  grazia = '38313740',
  goodhouse = '31537308',
  esquire = '44922469',
  girlspower = '',
}
