import axios from 'axios'
import { ActionListPayload, ActionListResult } from '@/types/action'
import { Article } from '@/types/article'

export default async function fetchBiograhies(
  params: ActionListPayload
): Promise<ActionListResult<Article>> {
  const { data } = await axios.get(`/api/v1/article/dic/biography/`, {
    params,
  })
  return data
}
