import axios from 'axios'

export default async function updateFile({
  id,
  data,
}: {
  id: number
  data: FormData
}): Promise<File> {
  const {
    data: { item },
  } = await axios.post(`/api/v1/file/${id}/`, data)

  return item
}
