import axios from 'axios'
import { Answer, AnswerCreate } from '@/types/test'
import { ActionItemResult } from '@/types/action'

export default async function createTestQuestionAnswer(
  item: AnswerCreate
): Promise<ActionItemResult<Answer>> {
  const { data } = await axios.post('/api/v1/test-question-answer/', item)
  return data
}
