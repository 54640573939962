import { Mutation } from 'vuex'
import { SocialDistributionState } from '../types'

const setOptions: Mutation<SocialDistributionState> = (state, payload) => {
  state.items.value = state.items.value.map((social) => {
    return {
      ...social,
      text: !social.id ? payload.text || social.text : social.text,
      active_from: !social.id
        ? payload.active_from ?? social.active_from
        : social.active_from,
    }
  })
  state.options.pasteText = payload.text || state.options.pasteText
  state.options.pasteDate = payload.active_from || state.options.pasteDate
}

export default setOptions
