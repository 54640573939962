import axios from 'axios'
import { ActionItemResult } from '@/types/action'
import { MailingDetailItem } from '@/types/mailing'

export default async function fetchMailingItem({
  id,
  ...params
}: {
  [key: string]: string | number
  id: number
}): Promise<ActionItemResult<MailingDetailItem>> {
  const { data } = await axios.get<ActionItemResult<MailingDetailItem>>(
    `/api/v1/mailing/${id}`,
    {
      params,
    }
  )

  return data
}
