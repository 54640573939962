import axios from 'axios'

export default async function updateContactPerson({
  id,
  role,
  full_name,
  email,
  phone,
}: {
  id: number
  role: string
  full_name: string
  email: string
  phone: string
}) {
  const {
    data: { items },
  } = await axios.put(`/api/v1/contacts-person/${id}/`, {
    role,
    full_name,
    email,
    phone,
  })

  return items
}
