import { PluginObject } from 'vue'
// @ts-ignore
import Sortable from 'sortablejs'
// @ts-ignore
import { Swap } from 'sortablejs/modular/sortable.core.esm'

export const VueDraggablePlugin: PluginObject<undefined> = {
  install() {
    Sortable.mount(new Swap())
  },
}
