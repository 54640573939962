import { ActionItemResult } from '@/types/action'
import { RecipeIngredient } from '@/types/recipe'
import axios from 'axios'

export default async function createRecipeIngredient(
  payload: Partial<RecipeIngredient>
): Promise<ActionItemResult<RecipeIngredient>> {
  const { data } = await axios.post('/api/v1/recipe-ingredient/', payload)
  return data
}
