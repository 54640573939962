import { PlatformOrigin } from '@/types/platform'

export function initAutoTargetLinkPlugin({
  editor,
  getPlatformOrigin,
}: {
  editor: CKEDITOR.editor
  getPlatformOrigin: () => PlatformOrigin
}) {
  let linkInputEl: HTMLInputElement | null = null
  let targetSelectEl: HTMLSelectElement | null = null

  const onChangeLinkInputEl = (event: Event) => {
    if (!targetSelectEl) {
      return
    }

    const target = event.target as HTMLInputElement
    const [host] = target.value.split('/')
    const origin = `https://${host}`

    const newValue = origin === getPlatformOrigin() ? '_self' : '_blank'

    if (newValue === targetSelectEl.value) {
      return
    }

    targetSelectEl.value = newValue

    targetSelectEl.dispatchEvent(new Event('change'))
  }

  const clearEvents = () => {
    linkInputEl?.removeEventListener('change', onChangeLinkInputEl)
    linkInputEl = null
    targetSelectEl = null
  }

  editor.on('dialogShow', ({ data }) => {
    if (data?._?.name !== 'link') {
      return
    }

    const linkDialogEl = (data?.parts?.dialog?.$ as HTMLElement) || null

    if (!linkDialogEl) {
      return
    }

    const [linkPanelEl = null, targetPanelEl = null] =
      linkDialogEl.querySelectorAll<HTMLElement>('.cke_dialog_page_contents')

    linkInputEl =
      linkPanelEl
        ?.querySelector('.cke_dialog_ui_hbox_last')
        ?.querySelector('input.cke_dialog_ui_input_text[type=text]') || null

    if (!linkInputEl) {
      return
    }

    targetSelectEl =
      targetPanelEl
        ?.querySelector('.cke_dialog_ui_hbox_first')
        ?.querySelector('select.cke_dialog_ui_input_select') || null

    if (!targetSelectEl) {
      return
    }

    linkInputEl.addEventListener('change', onChangeLinkInputEl)
  })

  editor.on('dialogHide', ({ data }) => {
    if (data?._?.name !== 'link') {
      return
    }

    clearEvents()
  })

  const destroy = () => {
    clearEvents()
  }

  editor.on('destroy', destroy)

  return {
    destroy,
  }
}
