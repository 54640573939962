import axios from 'axios'
import { FactGroup } from '@/types/fact'

export default async function updateFactGroup({
  id,
  data,
}: {
  id: number
  data: FactGroup
}) {
  await axios.put(`/api/v1/fact-group/${id}`, data)
}
