import axios from 'axios'
import transformFactToNote from '@/helpers/transformFactToNote'
import { ActionItemResult } from '@/types/action'
import { ArticleBlock } from '@/types/article'

export default async function updateBlock({
  id,
  articleBlock,
}: {
  id: number
  articleBlock: { articleId: number } & Partial<ArticleBlock>
}): Promise<ActionItemResult<ArticleBlock>> {
  const { data } = await axios.put(
    `/api/v1/rich-html-blocks/${id}/`,
    {
      ...transformFactToNote(articleBlock),
    },
    {
      autoLogout: false,
      useWS: {
        model: 'richHtmlBlock',
        itemId: articleBlock.articleId,
      },
    }
  )
  return data
}
