import { ActionItemResult } from '@/types/action'
import { Poll } from '@/types/poll'
import axios from 'axios'

export default async function createPoll({
  name,
  type,
}: {
  name: string
  type: number
}): Promise<ActionItemResult<Poll>> {
  const { data } = await axios.post('/api/v1/poll/', {
    name,
    type,
  })
  return data
}
