import { ActionTree } from 'vuex'
import { SocialDistributionState } from '../types'
import { RootState } from '@/store/types'

import fetchSocialDistribution from './fetchSocialDistribution'
import fetchSocialChannels from './fetchSocialChannels'

const actions: ActionTree<SocialDistributionState, RootState> = {
  fetchSocialDistribution,
  fetchSocialChannels,
}

export default actions
