import axios from 'axios'
import { ActionListPayload, ActionListResult } from '@/types/action'
import { Contact } from '@/types/contact'

export default async function fetchContacts({
  page,
  count,
}: ActionListPayload): Promise<ActionListResult<Contact>> {
  const { data } = await axios.get('/api/v1/contacts/', {
    params: {
      page,
      count,
    },
  })

  return data
}
