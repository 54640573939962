import { socials } from '@/config/socialDistribution'
import { ModelId } from './api'

export enum SocialDistributionStatus {
  NEW = 'new',
  PUBLISHED = 'published',
  PUBLISHING = 'publishing',
  ERROR = 'error',
}

export type SocialCode = keyof typeof socials

export interface SocialDistribution {
  id: number
  cid: number
  model_id: ModelId
  item_id: number
  status: SocialDistributionStatus
  net_name: SocialCode
  channel: string
  channel_name?: string
  active_from: string | null
  republish_from: string | null
  text: string | null
  link?: string | null
  image_id: string | null
  enabled: boolean
}

export interface SocialGroup {
  code: string
  name: string
}
