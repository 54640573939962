export default function htmlToText(str?: string | null): string {
  if (!str) return ''

  const div = document.createElement('div')

  div.innerHTML = str

  const text = div.innerText

  return !text && div.querySelectorAll('ul, ol, li').length > 1 ? ' ' : text
}
