import { Feature } from '@/types/feature'
import axios from 'axios'

export default async function updateFeature({
  id,
  data,
}: {
  id: number
  data: Partial<Feature>
}) {
  await axios.put(`/api/v1/feature-article/${id}`, data)
}
