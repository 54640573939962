import { ActionItemResult } from '@/types/action'
import { Section } from '@/types/section'
import axios from 'axios'

export default async function fetchSection({
  id,
  withItem,
}: {
  id: number
  withItem: string
}): Promise<ActionItemResult<Section>> {
  const { data } = await axios.get(`/api/v1/section/${id}`, {
    params: {
      with: withItem,
    },
    autoLogout: false,
  })
  return data
}
