import axios from 'axios'
import { ActionListPayload, ActionListResult } from '@/types/action'
import { Comment } from '@/types/comment'

export default async function fetchComments({
  page,
  count,
  withItem = 'user,article',
  filter,
  sort,
  cancelToken,
  projectId,
}: ActionListPayload): Promise<ActionListResult<Comment>> {
  const headers: Record<string, string | number> = {}

  if (projectId) {
    headers['X-Project-ID'] = projectId
  }
  const { data } = await axios.get('/api/v1/comment/', {
    cancelToken,
    headers,
    params: {
      page,
      count,
      with: withItem,
      filter,
      sort,
    },
    useRetry: false,
  })

  return data
}
