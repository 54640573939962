import axios from 'axios'

export default async function deleteBlock({
  id,
  item_id,
}: {
  id: number
  item_id: number
}) {
  return axios.delete(`/api/v1/rich-html-blocks/${id}/`, {
    autoLogout: false,
    useWS: {
      model: 'richHtmlBlock',
      itemId: item_id,
    },
  })
}
