import { Mutation } from 'vuex'
import { SocialDistributionState } from '../types'

const setSocialDistributionPublished: Mutation<SocialDistributionState> = (
  state,
  payload
) => {
  state.publishedGroup = payload
}

export default setSocialDistributionPublished
