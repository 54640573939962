import axios from 'axios'
import { ActionItemResult } from '@/types/action'
import { MailingDetailItem } from '@/types/mailing'

export default async function updateMailingItem({
  id,
  ...payload
}: Partial<
  Pick<
    MailingDetailItem,
    | 'name'
    | 'pre_header'
    | 'status'
    | 'active'
    | 'date_from'
    | 'date_to'
    | 'dispatch_date'
    | 'interest_id'
    | 'custom_subject'
    | 'segment_external_id'
    | 'custom_button_caption'
    | 'custom_button_url'
    | 'custom_description'
    | 'custom_image_id'
  >
> & {
  id: number
}): Promise<ActionItemResult<MailingDetailItem>> {
  const { data } = await axios.put<ActionItemResult<MailingDetailItem>>(
    `/api/v1/mailing/${id}`,
    payload
  )

  return data
}
