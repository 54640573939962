import { Article } from '@/types/article'
import axios from 'axios'

export default async function createPinnedArticle(payload: {
  id: number
  position: number
}): Promise<Article> {
  const { data } = await axios.post('/api/v1/fixed/pinned-article/add', payload)
  return data
}
