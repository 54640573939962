var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.hiddenCounter && _vm.maxlength)?_c('CounterValue',{class:[
    'sirius-editor',
    {
      'sirius-editor_disabled': _vm.disabled,
    },
  ],attrs:{"value":_vm.localValue,"maxlength":_vm.maxlength}},[_c('ckeditor',_vm._g(_vm._b({ref:"CKEditor",attrs:{"config":_vm.localConfig,"throttle":0},on:{"ready":_vm.onReady}},'ckeditor',_vm.$attrs,false),_vm.localListeners))],1):_c('ckeditor',_vm._g(_vm._b({class:[
    'sirius-editor',
    {
      'sirius-editor_disabled': _vm.disabled,
    },
  ],attrs:{"config":_vm.localConfig,"throttle":0},on:{"ready":_vm.onReady}},'ckeditor',_vm.$attrs,false),_vm.localListeners))
}
var staticRenderFns = []

export { render, staticRenderFns }