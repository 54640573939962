import axios from 'axios'

export default async function addImageMediaLibrary({
  essenceId,
  essence,
  image_id,
}: {
  image_id: number
  essence: string
  essenceId: number
}) {
  const { data } = await axios.post(`/api/v1/${essence}-media-library/`, {
    [`${essence}_id`]: essenceId,
    image_id,
  })

  return data
}
