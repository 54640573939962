import { ActionItemResult } from '@/types/action'
import { Popblock } from '@/types/popblock'
import axios from 'axios'

export default async function createPopblock(
  payload: Partial<Popblock>
): Promise<ActionItemResult<Popblock>> {
  const { data } = await axios.post('/api/v1/popblock/', payload)

  return data
}
