import axios from 'axios'
import { ActionGroupedListResult } from '@/types/action'
import { SocialDistribution, SocialGroup } from '@/types/socialDistribution'

type ResponseSocialDistribution = ActionGroupedListResult<
  Record<string, SocialDistribution[]>,
  SocialDistribution,
  { channels: SocialGroup[] }
>
export default async function fetchSocialChannels(): Promise<ResponseSocialDistribution> {
  const { data } = await axios.get<ResponseSocialDistribution>(
    '/api/v1/social-distribution/meta'
  )

  return data
}
