import { ActionItemResult } from '@/types/action'
import { TechnicalArticleItem } from '@/types/technical'
import axios from 'axios'

export default async function fetchTechnicalArticle({
  id,
}: {
  id: number
}): Promise<ActionItemResult<TechnicalArticleItem>> {
  const { data } = await axios.get(`/api/v1/technical/${id}`)
  return data
}
