import { ActionItemResult } from '@/types/action'
import { Popup } from '@/types/popup'
import axios from 'axios'

export default async function updatePopup({
  id,
  ...payload
}: Pick<Popup, 'id'> & Partial<Omit<Popup, 'id'>>): Promise<
  ActionItemResult<Popup>
> {
  return axios.put(`/api/v1/popup/${id}`, payload)
}
