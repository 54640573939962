import { TechnicalForm } from '@/types/technical'
import axios from 'axios'

export default async function editTechnicalArticle({
  id,
  formFields,
}: {
  id: number
  formFields: Omit<TechnicalForm, 'image'>
}) {
  await axios.put(`/api/v1/technical/${id}`, formFields)
}
