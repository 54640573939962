import axios from 'axios'

export default async function deleteFixedArticle(payload: {
  id: number
  section_id: number
}) {
  const { data } = await axios.post(
    '/api/v1/fixed/fixed-article/remove',
    payload
  )
  return data
}
