import { EfficientTitle } from '@/types/article'
import axios from 'axios'

export default async function updateArticleEfficientTitle({
  id,
  ...payload
}: EfficientTitle): Promise<EfficientTitle> {
  const { data } = await axios.put(
    `/api/v1/article-efficient-title/${id}`,
    payload
  )

  return data
}
