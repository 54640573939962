import { Popblock } from '@/types/popblock'
import axios from 'axios'

export default async function editPopblock({
  id,
  popblock,
}: {
  id: number
  popblock: Partial<Popblock>
}) {
  await axios.put(`/api/v1/popblock/${id}`, { ...popblock })
}
