import { ActionItemResult } from '@/types/action'
import { RecipeIngredient } from '@/types/recipe'
import axios, { CancelToken } from 'axios'

export default async function fetchRecipeIngredientNorm({
  id,
  withItem,
  cancelToken,
  ...params
}: {
  id: number
  withItem?: string
  cancelToken?: CancelToken
}): Promise<ActionItemResult<RecipeIngredient>> {
  const { data } = await axios.get(`/api/v1/recipe-ingredient-norm/${id}`, {
    cancelToken,
    params: {
      ...params,
      with: withItem,
    },
  })

  return data
}
