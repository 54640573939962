import axios from 'axios'
import { ActionItemResult } from '@/types/action'
import { MailingArticle } from '@/types/mailing'

type CreateMailingArticlePayload = Pick<
  MailingArticle,
  'mailing_id' | 'article_id' | 'name'
> &
  Partial<Pick<MailingArticle, 'sort' | 'description' | 'button_caption'>>

export default async function createMailingArticle(
  payload: CreateMailingArticlePayload
): Promise<ActionItemResult<MailingArticle>> {
  const { data } = await axios.post(`/api/v1/mailing-article/`, payload)

  return data
}
