import { namespace } from 'vuex-class'

export const modalModule = namespace('modal')
export const sidebarModule = namespace('sidebar')
export const articleModule = namespace('article')
export const userModule = namespace('user')
export const authModule = namespace('auth')
export const fixedModule = namespace('fixed')
export const videoWidgetModule = namespace('videoWidget')
export const socialDistributionModule = namespace('socialDistribution')
export const podcastModule = namespace('podcast')
export const episodeModule = namespace('episode')
