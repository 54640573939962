import { ActionListPayload } from '@/types/action'
import axios from 'axios'

export default async function fetchStatisticTrafficSource(
  params: ActionListPayload & {
    traffic_source?: string
    event_action?: string
    event_category?: string
    type?: string
    views_date?: string | string[]
    page?: number
    count?: number
  }
): Promise<any> {
  const data = await axios.get(`/api/v1/statistic/tracker/traffic-source`, {
    params,
  })

  return data.data
}
