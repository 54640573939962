import { isLocalhost } from '@/lib/mode'

const {
  APP_BAR_HEIGHT,
  APP_SIDEBAR_WIDTH,
  APP_MINI_SIDEBAR_WIDTH,
}: {
  APP_BAR_HEIGHT: number
  APP_SIDEBAR_WIDTH: number
  APP_MINI_SIDEBAR_WIDTH: number
  // eslint-disable-next-line @typescript-eslint/no-var-requires
} = require('../../commonParams')

export const SIRIUS_DOMAIN =
  process.env.VUE_APP_SIRIUS_DOMAIN ?? window.location.hostname

export const APP_FRONT_ENV = ((hostname: string) => {
  const [appFrontEndFromHostName] = hostname.match(/^qa.|dev.|staging./) ?? []

  if (appFrontEndFromHostName) {
    return appFrontEndFromHostName.replace(/\.$/, '').toUpperCase()
  }

  return process.env.VUE_APP_FRONT_ENV ?? 'PROD'
})(SIRIUS_DOMAIN)

export const IS_PROD = process.env.NODE_ENV === 'production'
export const API_AUTH_ORIGIN = process.env.VUE_APP_AUTH_ORIGIN
export const API_RECS_ORIGIN = process.env.VUE_APP_RECS_ORIGIN

export const ENABLED_SENTRY = !isLocalhost()
export const ENABLED_OPENREPLAY = APP_FRONT_ENV === 'PROD' && IS_PROD
export { APP_BAR_HEIGHT, APP_SIDEBAR_WIDTH, APP_MINI_SIDEBAR_WIDTH }

console.log('API_RECS_ORIGIN', API_RECS_ORIGIN)
