import { ActionListPayload, ActionItemResult } from '@/types/action'
import { AdBannerPosition } from '@/types/advSwitch'
import axios from 'axios'

export default async function updateBannerPosition(
  payload: ActionListPayload
): Promise<ActionItemResult<AdBannerPosition>> {
  const { data } = await axios.put(
    `/api/v1/ad-site-banner-position/${payload.id}`,
    { active: payload.active }
  )
  return data
}
