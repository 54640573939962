import { DistributionItem } from '@/types/widget/video'
import axios from 'axios'

export default async function editVideoDistribution({
  id,
  ...payload
}: Pick<DistributionItem, 'id'> &
  Partial<
    Pick<DistributionItem, 'description' | 'name' | 'no_comments' | 'tags'>
  >) {
  axios.put(`/api/v1/video-distribution/${id}`, {
    ...payload,
  })
}
