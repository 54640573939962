import { Getter } from 'vuex'
import { RootState } from '@/store/types'
import { ArticleGetters, ArticleState } from '../types'
import { ArticleTypeCode } from '@/types/article'

const articleTypeCode: Getter<ArticleState, RootState> = (
  state,
  getters: ArticleGetters
) => {
  const getter: ArticleGetters['articleTypeCode'] = (id) => {
    if (!id) {
      return ArticleTypeCode.GENERAL
    }

    const code = getters['articleTypeById'](id)?.caption as
      | ArticleTypeCode
      | undefined

    return code || ArticleTypeCode.GENERAL
  }

  return getter
}

export default articleTypeCode
