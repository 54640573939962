var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-app',{style:({ '--window-inner-height': `${_vm.$vuetify.breakpoint.height}px` })},[_c('div',{style:({ display: 'none' })},[_c('Editor',{attrs:{"config":{
        toolbar: [],
      },"editorUrl":"/lib/ckeditor4/ckeditor.js"},on:{"ready":_vm.onLoadCkeditor}})],1),_c(_vm.layoutComponent,{tag:"component",attrs:{"loading":_vm.loading}},[(_vm.$root.pageStatusCode === 404)?_c('NotFound404'):_vm._e(),_c('div',{class:{
        'flex-grow-1': _vm.$root.pageStatusCode !== 404,
        'd-none': _vm.$root.pageStatusCode === 404,
        'd-flex flex-column':
          _vm.$root.pageStatusCode !== 404 && _vm.layoutComponent === 'AuthLayout',
      }},[(_vm.isReady)?_c('router-view',{key:_vm.routerViewKey}):_vm._e()],1)],1),_c('Notifications')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }