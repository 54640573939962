import { ActionListPayload, ActionListResult } from '@/types/action'
import { User } from '@/types/user'
import axios from 'axios'

export default async function fetchUsers({
  page,
  count,
  filter,
  sort,
  cancelToken,
  is_author,
  withItem,
}: ActionListPayload): Promise<ActionListResult<User>> {
  const { data } = await axios.get('/api/v1/user/', {
    cancelToken,
    params: {
      page,
      count,
      filter: filter || null,
      with: withItem,
      sort: sort || null,
      is_author,
    },
  })

  return data
}
