import { ActionItemResult } from '@/types/action'
import { FactGroup } from '@/types/fact'
import axios from 'axios'

export default async function createFactGroup({
  name,
}: {
  name: string
}): Promise<ActionItemResult<FactGroup>> {
  const { data } = await axios.post('/api/v1/fact-group/', { name })
  return data
}
