import { Action } from 'vuex'
import SiriusAPI from '@/api'
import { RootState } from '@/store/types'
import { SocialDistributionState } from '../types'
import { SocialDistribution, SocialGroup } from '@/types/socialDistribution'
import { ActionGroupedListResult } from '@/types/action'

let activeRequest: Promise<
  ActionGroupedListResult<
    Record<string, SocialDistribution[]>,
    SocialDistribution,
    { channels: SocialGroup[] }
  >
> | null = null

const fetchSocialDistribution: Action<SocialDistributionState, RootState> =
  async ({ commit }, payload) => {
    if (activeRequest) {
      return activeRequest
    }

    commit('setSocialDistributionLoading', true)

    try {
      activeRequest =
        SiriusAPI.socialDistribution.fetchSocialDistribution(payload)

      const { items, meta, groupedItems } = await activeRequest

      commit('setGroups', meta?.fields?.channels || {})
      commit('setSocialDistributionItems', items)
      commit('setSocialDistributionPublished', groupedItems)
    } catch (error) {
      commit('setGroups', {})
      commit('setSocialDistributionItems', [])
      commit('setSocialDistributionPublished', null)

      console.error(error)
    } finally {
      commit('setSocialDistributionLoading', false)
      activeRequest = null
    }
  }

export default fetchSocialDistribution
