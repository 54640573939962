import axios from 'axios'
import { ActionItemResult } from '@/types/action'
import { DistributionNativePost } from '@/types/distribution'

export default async function fetchNativePost({
  id,
  params,
}: {
  id: number
  params: { with: string }
}): Promise<ActionItemResult<DistributionNativePost>> {
  const { data } = await axios.get(`/api/v1/native-post/${id}`, {
    params,
  })
  return data
}
