import * as Sentry from '@sentry/vue'
import { AuthGetters } from '@/store/modules/auth/types'
import { UserGetters, UserState } from '@/store/modules/user/types'
import {
  articleModule,
  authModule,
  sidebarModule,
  userModule,
} from '@/store/namespaces'
import RequestStatus from '@/types/requestStatus'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import { RootGetters, RootMutations } from '@/store/types'
import { SidebarMutations } from '@/store/modules/sidebar/types'
import { SidebarType } from '@/types/sidebar'
import { ArticleActions } from '@/store/modules/article/types'
import { ProjectID } from '@/types/platform'
import { ENABLED_SENTRY } from '@/config/base'
import { OpenreplayTracker } from '@/plugins/openreplay'
import checkOnline from '@/helpers/checkOnline'
//@ts-ignore
import initPlugin from '@/config/ckeditor/plugins/cosmohint/plugin'
import { stopWordsChannel } from '@/plugins/websocket/channels'
import { stopWordNoticeEvent } from '@/plugins/websocket/events'
import { initAutoTargetLinkPlugin } from '@/components/Editor/plugins/autoTargetLink'

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate',
])

@Component({
  components: {
    DefaultLayout: () => import('@/layouts/DefaultLayout/DefaultLayout.vue'),
    AuthLayout: () => import('@/layouts/AuthLayout/AuthLayout.vue'),
    NotFound404: () => import('@/components/NotFound404/NotFound404.vue'),
  },
})
export default class App extends Vue {
  @Getter('getPlatform') private readonly platform!: RootGetters['getPlatform']
  @Getter private readonly getPlatformOrigin!: RootGetters['getPlatformOrigin']
  @Getter private readonly getProjectId!: RootGetters['getProjectId']
  @userModule.Getter
  private readonly getUserId!: UserGetters['getUserId']
  @userModule.Getter
  private readonly getUserFullName!: UserGetters['getUserFullName']
  @userModule.Getter
  private readonly getUserEmail!: UserGetters['getUserEmail']
  @authModule.Getter
  private readonly getToken!: UserGetters['getToken']
  @Mutation('setOnline')
  private readonly setOnlineMutation!: RootMutations['setOnline']

  @sidebarModule.Mutation toggleSidebar!: SidebarMutations['toggleSidebar']
  @authModule.Getter
  private readonly isAuthenticated!: AuthGetters['isAuthenticated']
  @userModule.State('current')
  private readonly currentUser!: UserState['current']

  @articleModule.Action('fetchArticleFields')
  private readonly fetchArticleFieldsAction!: ArticleActions['fetchArticleFields']
  @articleModule.Getter('articleName')
  private readonly articleName!: string

  private requestStatusFetchUser = RequestStatus.NOT_STARTED
  private isReady = false
  private maxLengthArticleName = 35

  private get layoutComponent() {
    const { layout = 'Default' } = this.$route.meta

    return `${layout}Layout`
  }

  private get routerViewKey() {
    return this.$route.path
  }

  private get loading() {
    return this.requestStatusFetchUser === RequestStatus.PENDING
  }

  @Watch('platform', { immediate: true })
  private async onPlatformChangeFontsLoad() {
    this.fetchArticleFieldsAction()
  }

  @Watch('$route.path', { immediate: true })
  private autoToggleAppSidebar() {
    const isMobile = !this.$vuetify.breakpoint.lgAndUp
    const appSidebarOpened =
      isMobile || this.$route.meta.appSidebarOpened === false ? false : true

    this.toggleSidebar({
      sidebar: {
        type: SidebarType.APP,
      },
      status: 'opened',
      value: appSidebarOpened,
    })
  }

  private configureScopeTrackers() {
    this.$watch(
      () => {
        return this.getUserId(ProjectID[this.platform])
      },
      (userId) => {
        OpenreplayTracker?.setUserID(userId.toString())
        OpenreplayTracker?.setMetadata('userName', this.getUserFullName)
        OpenreplayTracker?.setMetadata('email', this.getUserEmail)

        if (ENABLED_SENTRY) {
          Sentry.configureScope((scope) => {
            scope.setUser({
              id: userId.toString(),
              email: this.getUserEmail,
              userName: this.getUserFullName,
              token: this.getToken,
            })
          })
        }
      },
      { immediate: true }
    )
  }

  private onLoadCkeditor() {
    if (this.isReady) return

    CKEDITOR.config.language = 'ru'
    CKEDITOR.config.basicEntities = false
    CKEDITOR.config.entities_additional = ['#39', 'gt', 'lt'].join(',')

    CKEDITOR.config.plugins = CKEDITOR.config.plugins
      ?.split(',')
      .filter(
        (plugin) =>
          ![
            'contextmenu',
            'tableselection',
            'tabletools',
            'pastefromlibreoffice',
            'pastefromword',
            'pastefromgdocs',
          ].includes(plugin)
      )
      .join(',')

    CKEDITOR.on('instanceReady', (evt) => {
      initAutoTargetLinkPlugin({
        editor: evt.editor,
        getPlatformOrigin: () => this.getPlatformOrigin,
      })
    })

    initPlugin()
    this.isReady = true
  }

  private initStopWordsNotice() {
    const channels: string[] = []
    this.$watch(
      () => {
        return [this.$route.path, this.articleName]
      },
      () => {
        const isArticlePage = this.$route.name === 'content_article'
        const idArticle = this.$route.params.id

        if (!isArticlePage || channels.includes(idArticle) || !this.articleName)
          return
        this.subscribeStopWords(+idArticle, this.articleName, this.$route.path)
        channels.push(idArticle)
      }
    )
  }

  private subscribeStopWords(idArticle: number, name: string, url: string) {
    this.$echo
      .channel(
        stopWordsChannel({
          projectId: this.getProjectId,
          model: 'article',
          itemId: idArticle,
          type: 'inoagent',
        })
      )
      .listen(
        stopWordNoticeEvent,
        ({
          resource,
          allWords,
        }: {
          resource: string | null
          allWords: string[]
        }) => this.onWebSocketInoagent({ resource, allWords }, name, url)
      )
  }

  private onWebSocketInoagent(
    { allWords }: { resource: string | null; allWords: string[] },
    name: string,
    url: string
  ) {
    if (name.length > this.maxLengthArticleName)
      name = `${name.slice(0, this.maxLengthArticleName)}...`

    const list = (
      Array.isArray(allWords) ? allWords : Object.values(allWords)
    ).join('<br />')
    const message = `В статье "${name}" найдены упоминания иноагента.<br /><br />${list}<br /><br /> Проверьте наличие маркировки: <a class="white--text notification__text font-weight-bold" href="${url}?check_agents=1">Перейти </a>`

    this.showAlert(message)
  }

  private showAlert(link: string) {
    this.$notify({
      type: 'error',
      title: link,
      autoCloseTime: 0,
    })
  }

  private async iniOnlineListenner() {
    const isOnline = await checkOnline()

    this.setOnlineMutation(isOnline)

    window.addEventListener('online', () => {
      this.setOnlineMutation(true)
    })

    window.addEventListener('offline', () => {
      this.setOnlineMutation(false)
    })
  }

  private created() {
    this.configureScopeTrackers()
  }

  private mounted() {
    this.iniOnlineListenner()
    this.initStopWordsNotice()
  }
}
