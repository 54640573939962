import { ActionItemResult } from '@/types/action'
import { AdSiteSection } from '@/types/advSwitch'
import axios from 'axios'

export default async function createAdSiteSection(
  id: number
): Promise<ActionItemResult<AdSiteSection>> {
  const { data } = await axios.post(`/api/v1/ad-site-section/`, {
    section_id: id,
  })
  return data
}
