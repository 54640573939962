import { ActionItemResult } from '@/types/action'
import { Poll } from '@/types/poll'
import axios from 'axios'

export default async function fetchPoll({
  id,
  withItem,
}: {
  id: number
  withItem: string
}): Promise<ActionItemResult<Poll>> {
  const { data } = await axios.get(`/api/v1/poll/${id}`, {
    params: { with: withItem },
  })
  return data
}
