import { Getter } from 'vuex'
import { UserState } from '../types'
import { RootState } from '@/store/types'

const getUserFullName: Getter<UserState, RootState> = (state, getters) => {
  const firstName = getters['getUserFirstName']
  const lastName = getters['getUserLastName']

  return [firstName, lastName].filter((w) => w?.trim().length).join(' ')
}

export default getUserFullName
