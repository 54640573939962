import axios from 'axios'
import { ActionItemResult } from '@/types/action'
import { DistributionNativePost } from '@/types/distribution'

export default async function createNativePost(payload: {
  active: boolean
  publish_date: string
  text: string
  gallery_id: number | null
  video_id: number | null
  images: (number | null)[]
}): Promise<ActionItemResult<DistributionNativePost>> {
  const { data } = await axios.post(`/api/v1/native-post/`, payload)
  return data
}
