import getLetters from './getLetters'

export default function sliceText({
  text,
  start,
  end,
}: {
  text: string
  start: number
  end?: number
}): string {
  return getLetters(text).slice(start, end).join('')
}
