import deepFreeze from '@/helpers/deepFreeze'
import { Mutation } from 'vuex'
import { PodcastState } from '../types'

const setPodcast: Mutation<PodcastState> = (state, payload) => {
  console.log('payload', payload)
  state.activePodcast = deepFreeze(payload)
}

export default setPodcast
