import { Fact } from '@/types/fact'
import axios from 'axios'

export default async function updateFact({
  id,
  data,
}: {
  id: number
  data: Fact
}) {
  await axios.put(`/api/v1/fact/${id}`, data)
}
