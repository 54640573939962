import { RootState } from '@/store/types'
import { GetterTree } from 'vuex'
import { ArticleState } from '../types'
import articleTypesList from './articleTypesList'
import articleTypeById from './articleTypeById'
import articleTypeByCode from './articleTypeByCode'
import articleTypeCode from './articleTypeCode'
import articleName from './articleName'
import isLoadingFields from './isLoadingFields'

const getters: GetterTree<ArticleState, RootState> = {
  articleTypesList,
  articleTypeById,
  articleTypeCode,
  articleTypeByCode,
  articleName,
  isLoadingFields,
}

export default getters
