import { ActionItemResult } from '@/types/action'
import { ArticleBlock } from '@/types/article'
import axios from 'axios'

export default async function fetchBlockByExternalId({
  articleId,
  externalId,
}: {
  articleId: number
  externalId: string
}): Promise<ActionItemResult<ArticleBlock>> {
  const { data } = await axios.get(
    `/api/v1/rich-html-blocks/article/${articleId}/${externalId}`,
    {
      autoLogout: false,
    }
  )

  return data
}
