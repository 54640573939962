import axios from 'axios'
import { ActionItemResult } from '@/types/action'
import { Interest } from '@/types/interest'

export default async function createInterest({
  name,
}: {
  name: string
}): Promise<ActionItemResult<Interest>> {
  const { data } = await axios.post('/api/v1/interest/', { name })
  return data
}
