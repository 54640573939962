import { ActionTree } from 'vuex'
import { ArticleState } from '../types'
import { RootState } from '@/store/types'

import fetchArticleFields from './fetchArticleFields'
import deleteActiveUserFromArticle from './deleteActiveUserFromArticle'

const actions: ActionTree<ArticleState, RootState> = {
  fetchArticleFields,
  deleteActiveUserFromArticle,
}

export default actions
