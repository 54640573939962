import axios from 'axios'
import { ActionListPayload, ActionListResult } from '@/types/action'
import { StatisticAllViews } from '@/types/statistics'

export default async function fetchStatisticArticleAllViews({
  cancelToken,
  ids,
  ...params
}: ActionListPayload & {
  period?: string
  periodPublished?: string
  month?: number
  year?: number
  from?: string
  to?: string
  ids?: number[]
}): Promise<ActionListResult<StatisticAllViews>> {
  const { data } = await axios.get('/api/v1/statistic/article/all-views/', {
    cancelToken,
    params: {
      ...params,
      ids: ids?.join(','),
    },
  })

  return data
}
