import axios from 'axios'
import { ActionListResult } from '@/types/action'
import { Article } from '@/types/article'

export default async function fetchStarFamilyStatus(): Promise<
  ActionListResult<Article>
> {
  const { data } = await axios.get(`/api/v1/star-family-status/`)
  return data
}
