import { EfficientTitle } from '@/types/article'
import axios from 'axios'

export default async function createArticleEfficientTitle(
  payload: Omit<EfficientTitle, 'id'>
): Promise<EfficientTitle> {
  const { data } = await axios.post('/api/v1/article-efficient-title/', payload)

  return data
}
