import { ActionItemResult } from '@/types/action'
import { EmbedWidgetParams } from '@/types/articleWidgets'
import axios from 'axios'

export default async function updateVideoByCode({
  id,
  code,
  orientation,
}: {
  id: number
  code: string
  orientation?: number | null
}): Promise<ActionItemResult<EmbedWidgetParams>> {
  const { data } = await axios.put(`/api/v1/video/update-by-code/${id}`, {
    code,
    orientation,
  })
  return data
}
