import axios from 'axios'
import { ActionListPayload, ActionListResult } from '@/types/action'
import { ContactDepartment } from '@/types/contact'

export default async function fetchContactsDepartments({
  page,
  count,
}: ActionListPayload): Promise<ActionListResult<ContactDepartment>> {
  const { data } = await axios.get<ActionListResult<ContactDepartment>>(
    '/api/v1/contacts-department/',
    {
      params: { page, count },
    }
  )

  data.items.forEach((item) => {
    item.persons.sort((a, b) => a.sort - b.sort)
  })

  data.items.sort((a, b) => b.sort - a.sort)

  return data
}
