import axios from 'axios'
import { StatisticPayload } from '@/types/statistics'

export default async function fetchStatisticAuthors(params: StatisticPayload) {
  const { data } = await axios.get(
    '/api/v1/statistic/article/views/by-authors/',
    {
      params,
    }
  )
  return data
}
