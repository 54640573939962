import axios from 'axios'
import { ActionListPayload, ActionListResult } from '@/types/action'
import { ArticleForComment } from '@/types/article'

export default async function fetchArticlesForComment({
  projectId,
  ...params
}: ActionListPayload): Promise<ActionListResult<ArticleForComment>> {
  const headers: Record<string, string | number> = {}

  if (projectId) {
    headers['X-Project-ID'] = projectId
  }
  const { data } = await axios.get<ActionListResult<ArticleForComment>>(
    '/api/v1/article/with-comments/',
    {
      params,
      headers,
    }
  )

  return data
}
