import { Getter } from 'vuex'
import { UserGetters, UserState } from '../types'
import { RootState } from '@/store/types'
import { ProjectID } from '@/types/platform'
import { Role } from '@/types/role'

const checkAccessUserPermission: Getter<UserState, RootState> = (
  _,
  { getProjectAccessUserRoles }: UserGetters,
  rootState
) => {
  const getter: UserGetters['checkAccessUserPermission'] = ({
    field,
    permission,
    page,
    projectId = ProjectID[rootState.platform],
  }) => {
    const fullAccessRoles = [Role.ADMIN]
    const projectAccessUserRoles = getProjectAccessUserRoles(projectId)

    if (
      fullAccessRoles.some((fullAccessRole) =>
        projectAccessUserRoles.includes(fullAccessRole)
      )
    ) {
      return true
    }

    const permissions = rootState.auth.permissions

    if (!permissions) {
      return true
    }

    const projectPermission = permissions[`p${projectId}`]

    if (!projectPermission) {
      return false
    }

    const pagePermission = projectPermission[page]

    if (!pagePermission) {
      return false
    }

    if (!field) {
      return !!pagePermission.permissions?.includes(permission)
    }

    if (!pagePermission.fields) {
      return false
    }

    const fieldPermission = pagePermission.fields[field]

    if (!fieldPermission) {
      return false
    }

    return fieldPermission.permissions.includes(permission)
  }

  return getter
}

export default checkAccessUserPermission
