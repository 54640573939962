import { ActionListPayload, ActionListResult } from '@/types/action'
import { Section } from '@/types/section'
import axios from 'axios'

export default async function fetchSections({
  filter,
  withItem,
  count,
  sort,
  cancelToken,
}: ActionListPayload): Promise<ActionListResult<Section>> {
  const { data } = await axios.get('/api/v1/section', {
    cancelToken,
    params: {
      sort,
      count,
      filter,
      with: withItem,
    },
  })
  return data
}
