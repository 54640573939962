import { GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { AuthState } from '../types'

import isAuthenticated from './isAuthenticated'
import getToken from './getToken'

const getters: GetterTree<AuthState, RootState> = {
  isAuthenticated,
  getToken,
}

export default getters
