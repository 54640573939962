import axios from 'axios'
import { ActionItemResult, Field } from '@/types/action'
import { Popup, PopupType } from '@/types/popup'

export default async function fetchPopup(id: number): Promise<
  ActionItemResult<
    Popup,
    {
      type: Field[]
      priority: Field[]
      show_type: Field[]
      extra_fields: { afterClose: Field[]; showPeriod: Field[] }
    }
  >
> {
  const { data } = await axios.get(`/api/v1/popup/${id}`)

  const oldCollectEmailsForm = data.item.extra_fields.collectEmailsForm || 0
  const newCollectEmailsForm = +(
    data.item.type === PopupType.EMAIL_SUBSCRIPTION
  )

  if (oldCollectEmailsForm !== newCollectEmailsForm) {
    data.item.extra_fields.collectEmailsForm = newCollectEmailsForm

    await axios.put(`/api/v1/popup/${id}`, {
      extra_fields: {
        collectEmailsForm: newCollectEmailsForm,
      },
    })
  }

  return data
}
