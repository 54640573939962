import { Getter } from 'vuex'
import { RootState, RootGetters } from '@/store/types'

const resolveUrl = ({
  path,
  query = [],
}: {
  path: unknown[]
  query?: { key: string; value?: unknown }[]
}) => {
  return [
    path.concat(['']).join('/'),
    query
      .filter(({ value }) => value !== undefined && value !== null)
      .map(({ key, value }) => `${key}=${value}`)
      .join('&'),
  ]
    .filter((item) => item.trim().length > 0)
    .join('?')
}

const getMatrixUrl: Getter<RootState, RootState> = (
  state,
  getters: RootGetters
) => {
  const getter: RootGetters['getMatrixUrl'] = (payload) => {
    switch (payload.type) {
      case 'draft-article':
        return resolveUrl({
          path: [getters.getPlatformOrigin, 'draft', payload.params.id],
          query: [{ key: 'accessHash', value: payload.params.accessHash }],
        })

      case 'article':
        return resolveUrl({
          path: [
            payload.params.platform || getters.getPlatformOrigin,
            `-${payload.params.id}`,
          ],
          query: [{ key: 'accessHash', value: payload.params.accessHash }],
        })

      case 'podcast':
        return resolveUrl({
          path: [
            getters.getPlatformOrigin,
            'podcasts',
            payload.params.podcastCode,
          ],
        })

      case 'podcast-episode':
        return resolveUrl({
          path: [
            getters.getPlatformOrigin,
            'podcasts',
            payload.params.podcastCode,
            payload.params.episodeCode,
          ],
        })

      case 'tag':
        return resolveUrl({
          path: [getters.getPlatformOrigin, 'tag', payload.params.code],
        })

      case 'expert':
        return resolveUrl({
          path: [getters.getPlatformOrigin, 'experts', payload.params.code],
        })

      case 'recipe':
        return resolveUrl({
          path: [getters.getPlatformOrigin, 'recipes', payload.params.code],
        })

      case 'recipe-ingredient':
        return resolveUrl({
          path: [
            getters.getPlatformOrigin,
            'recipes',
            'ingredient',
            payload.params.code,
          ],
        })

      case 'recipe-category':
        return resolveUrl({
          path: [
            getters.getPlatformOrigin,
            'recipes',
            'category',
            payload.params.code,
          ],
        })

      case 'biography':
        return resolveUrl({
          path: [
            getters.getPlatformOrigin,
            sectionBio(getters.getPlatform),
            'biography',
            payload.params.code,
          ],
          query: [{ key: 'accessHash', value: payload.params.accessHash }],
        })

      case 'brands':
        return resolveUrl({
          path: [
            payload.params.platform || getters.getPlatformOrigin,
            `-${payload.params.code}`,
          ],
          query: [{ key: 'accessHash', value: payload.params.accessHash }],
        })

      default:
        return ''
    }
  }

  return getter
}

const sectionBio = (platform: string) => {
  return ['cosmo', 'goodhouse'].includes(platform) ? 'stars' : 'persons'
}

export default getMatrixUrl
