import { Issue } from '@/types/issue'
import axios from 'axios'

export default async function editIssue({
  id,
  issue,
}: {
  id: number
  issue: Issue
}) {
  await axios.put(`/api/v1/issue/${id}`, { ...issue })
}
