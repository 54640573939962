import { Action } from 'vuex'
import { RootState } from '@/store/types'
import { AuthState } from '../types'

const logOut: Action<AuthState, RootState> = (_, payload = {}) => {
  const { autoLogout = false } = payload

  localStorage.removeItem('authInfo')

  if (autoLogout) {
    const searchParams = new URLSearchParams()

    searchParams.append('error', 'unauthenticated')
    searchParams.append(
      'redirect',
      `${window.location.pathname}${window.location.search}`
    )

    window.location.href = `/sign-in?${searchParams.toString()}`
  } else {
    window.location.href = '/sign-in'
  }
}

export default logOut
