import axios from 'axios'
import { ArticleBlockBlockingInfo } from '@/types/article'

export default async function fetchBlockingRichBlocksList({
  model,
  item_id,
}: {
  model: string
  item_id: number
}): Promise<
  {
    blocking: ArticleBlockBlockingInfo
    id: number
  }[]
> {
  const {
    data: { items },
  } = await axios.get(
    `/api/v1/blocking/rich-html-blocks-list/${model}/${item_id}`
  )

  return items
}
