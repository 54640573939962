import cyrillicToLatin from './cyrillicToLatin'
import htmlToText from './htmlToText'

export default function urlCodeFromText(val: string): string {
  const code = cyrillicToLatin(
    htmlToText(val)
      .replace(/ |\n/g, '-')
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/æ/g, 'ae')
      .replace(/œ/g, 'oe')
      .toLowerCase()
  )
    .replace(/[^a-z0-9\- ]/gi, '-')
    .split('-')
    .join(' ')
    .split(' ')
    .filter((word) => !!word.trim().length)
    .join('-')

  return code
}
