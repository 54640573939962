import { GetterTree } from 'vuex'
import { RootState } from '../types'
import getPlatform from './getPlatform'
import getPlatformOrigin from './getPlatformOrigin'
import getMatrixUrl from './getMatrixUrl'
import getProjectId from './getProjectId'

const getters: GetterTree<RootState, RootState> = {
  getPlatform,
  getPlatformOrigin,
  getMatrixUrl,
  getProjectId,
}

export default getters
