import axios from 'axios'

export default async function cheatVideo({
  id,
  ...payload
}: {
  id: number
  views?: number
  likes?: number
}) {
  const { data } = await axios.put(`/api/v1/video/${id}/cheat`, payload)

  return data
}
