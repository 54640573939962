export default function deepFreeze<T>(o: T): T {
  if (!o) return o

  Object.freeze(o)

  Object.values(o).forEach((value) => {
    if (!Object.isFrozen(value)) {
      deepFreeze(value)
    }
  })

  return o
}
