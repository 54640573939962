import axios from 'axios'
import { ActionListPayload, ActionListResult } from '@/types/action'
import { Article } from '@/types/article'

export default async function fetchStarState({
  cancelToken,
  ...params
}: ActionListPayload): Promise<ActionListResult<Article>> {
  const { data } = await axios.get(`/api/v1/star-state/`, {
    cancelToken,
    params,
  })
  return data
}
