import axios from 'axios'
import { ActionListPayload, ActionResult } from '@/types/action'
import { DistributionWeekList } from '@/types/distribution'

export default async function fetchDistributionWeekList({
  cancelToken,
  ...params
}: ActionListPayload): Promise<ActionResult<DistributionWeekList>> {
  const { data } = await axios.get(`/api/v1/social-distribution/plan/weekly`, {
    cancelToken,
    params,
  })

  return data
}
