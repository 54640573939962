import { ActionItemResult } from '@/types/action'
import { Dish } from '@/types/recipe'
import axios, { CancelToken } from 'axios'

export default async function fetchRecipeDish({
  id,
  withItem,
  cancelToken,
  ...params
}: {
  id: number
  withItem?: string
  cancelToken?: CancelToken
}): Promise<ActionItemResult<Dish>> {
  const { data } = await axios.get(`/api/v1/recipe-dish/${id}`, {
    cancelToken,
    params: {
      ...params,
      with: withItem,
    },
  })

  return data
}
