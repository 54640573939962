import axios from 'axios'

import { DetailEpisode } from '@/types/episode'

export default async function updateEpisode(
  payload: DetailEpisode
): Promise<{ item: DetailEpisode }> {
  const { data } = await axios.put<{ item: DetailEpisode }>(
    `/api/v1/podcast-episode/${payload.id}`,
    {
      ...payload,
    }
  )

  return data
}
