import { ActionItemResult } from '@/types/action'
import { Popblock } from '@/types/popblock'
import axios, { CancelToken } from 'axios'

export default async function fetchPopblock({
  id,
  withItem,
  cancelToken,
}: {
  id: number
  withItem: string
  cancelToken?: CancelToken
}): Promise<ActionItemResult<Popblock>> {
  const { data } = await axios.get(`/api/v1/popblock/${id}`, {
    cancelToken,
    params: {
      with: withItem,
    },
  })
  return data
}
