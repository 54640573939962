import { parse as parseDate } from 'date-fns'
import { SocialDistributionStatus } from '@/types/socialDistribution'

export default function getSocialDistributionStatus({
  status,
  activeFrom,
  isComputeStatus = false,
}: {
  status?: SocialDistributionStatus
  activeFrom?: string | null
  isComputeStatus?: boolean
}) {
  if (!isComputeStatus) {
    return status || SocialDistributionStatus.NEW
  }

  if (
    status === SocialDistributionStatus.ERROR ||
    status === SocialDistributionStatus.PUBLISHING ||
    status === SocialDistributionStatus.PUBLISHED
  ) {
    return status
  }

  const activeFromDate = activeFrom
    ? parseDate(activeFrom, 'yyyy-MM-dd HH:mm:ss', new Date())
    : null

  status = status || SocialDistributionStatus.NEW

  if (
    status === SocialDistributionStatus.NEW &&
    activeFromDate &&
    activeFromDate.getTime() < Date.now()
  ) {
    return SocialDistributionStatus.PUBLISHING
  }

  if (activeFromDate && activeFromDate.getTime() > Date.now()) {
    return SocialDistributionStatus.NEW
  }

  return status
}
