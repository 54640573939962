import { ActionItemResult } from '@/types/action'
import { RecipeGroup } from '@/types/recipe'
import axios from 'axios'

export default async function updateRecipeIngredientsGroup(
  payload: Partial<RecipeGroup>
): Promise<ActionItemResult<RecipeGroup>> {
  const { data } = await axios.put(
    `/api/v1/recipe-ingredient-group/${payload.id}/`,
    payload
  )
  return data
}
