import axios from 'axios'

export default async function updateContactDepartment({
  id,
  code,
  title,
  new_issue,
}: {
  id: number
  code: string
  title: string
  new_issue: number
}) {
  const { data } = await axios.put(`/api/v1/contacts-department/${id}`, {
    code,
    title,
    new_issue,
  })

  return data
}
