import { RootState } from '@/store/types'
import { GetterTree } from 'vuex'
import { EpisodeState } from '../types'
import episodeName from './episodeName'

const getters: GetterTree<EpisodeState, RootState> = {
  episodeName,
}

export default getters
