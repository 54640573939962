import { ActionItemResult } from '@/types/action'
import { Transcribation } from '@/types/episode'
import axios from 'axios'

export default async function updateTranscribationText({
  id,
  ...payload
}: Transcribation): Promise<ActionItemResult<Transcribation>> {
  const { data } = await axios.put<ActionItemResult<Transcribation>>(
    `/api/v1/transcribation/${id}`,
    payload
  )

  return data
}
