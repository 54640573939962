import { ActionItemResult } from '@/types/action'
import { GalleryPhoto } from '@/types/galleryPhoto'
import axios from 'axios'

export default async function createGalleryPhoto({
  gallery_id,
  original_image_id,
}: {
  gallery_id: number
  original_image_id: number
}): Promise<ActionItemResult<GalleryPhoto>> {
  const { data } = await axios.post(
    '/api/v1/gallery-photo/',
    {
      gallery_id,
      original_image_id,
    },
    {
      useRetry: false,
    }
  )
  return data
}
