import { ActionItemResult } from '@/types/action'
import { Feature } from '@/types/feature'
import axios from 'axios'

export default async function createFeature(payload: {
  name: string
  placement: number
  type: number
  target: number
  active_from: string
}): Promise<ActionItemResult<Feature>> {
  const { data } = await axios.post('/api/v1/feature-article/', payload)
  return data
}
