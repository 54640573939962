import axios from 'axios'
import { Podcast, PodcastMeta } from '@/types/podcast'

export default async function fetchPodcast(
  id: number
): Promise<{ item: Podcast; meta: PodcastMeta }> {
  const { data } = await axios.get<{ item: Podcast; meta: PodcastMeta }>(
    `/api/v1/podcast/${id}`
  )
  return data
}
