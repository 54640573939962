import axios from 'axios'

export default async function addFile({
  itemId,
  model,
  data,
}: {
  model?: string
  itemId?: number
  data: FormData
}): Promise<File> {
  const {
    data: { item },
  } = await axios.post(`/api/v1/file/${model}/${itemId}/`, data)

  return item
}
