import { ActionItemResult } from '@/types/action'
import { Recipe } from '@/types/recipe'
import axios from 'axios'

export default async function createRecipe(payload: {
  name: string
  date: string
}): Promise<ActionItemResult<Recipe>> {
  const { data } = await axios.post('/api/v1/recipe/', payload)
  return data
}
