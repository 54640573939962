import { ActionItemResult } from '@/types/action'
import { Test } from '@/types/test'
import axios from 'axios'

export default async function fetchTest(
  id: number
): Promise<ActionItemResult<Test>> {
  const { data } = await axios.get(`/api/v1/test/${id}`, {
    params: {
      with: 'questions.answers,questions.answers.image,questions.image,questions.reverse_image,results.image',
    },
  })
  return data
}
