import axios from 'axios'
import { ActionListPayload, ActionListResult } from '@/types/action'
import { BlockWithoutPositions } from '@/types/block'

export default async function fetchBlocks<R = BlockWithoutPositions>({
  cancelToken,
  ...params
}: ActionListPayload): Promise<ActionListResult<R>> {
  const { data } = await axios.get<ActionListResult<R>>('/api/v1/block/', {
    cancelToken,
    params,
  })

  return data
}
