import { GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { SidebarState } from '../types'

import getSidebar from './getSidebar'

const getters: GetterTree<SidebarState, RootState> = {
  getSidebar,
}

export default getters
