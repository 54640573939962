import axios from 'axios'
import { ActionListResult } from '@/types/action'
import { MailingPinnedArticle } from '@/types/mailing'

export default async function fetchMailingFormArticles({
  interest_id,
  ...payload
}: {
  date_from: string
  date_to: string
  interest_id?: number
}): Promise<ActionListResult<MailingPinnedArticle>> {
  const { data } = await axios.get('/api/v1/mailing/form-articles/', {
    params: {
      ...payload,
      interest_id: interest_id || undefined,
    },
  })

  return data
}
