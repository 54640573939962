import axios from 'axios'
import { BlockArticle } from '@/types/block'
import { ActionItemResult } from '@/types/action'

export default async function updateBlockArticle({
  id,
  ...payload
}: Partial<BlockArticle> & Pick<BlockArticle, 'id'>): Promise<BlockArticle> {
  const {
    data: { item },
  } = await axios.put<ActionItemResult<BlockArticle>>(
    `/api/v1/block-article/${id}`,
    payload
  )

  return item
}
