import { ArticleState } from './types'

const state = (): ArticleState => ({
  isLoadingFields: false,
  fields: {
    types: {},
  },
  activeArticle: null,
})

export default state
