import { Platform } from '@/types/platform'
import { RootState } from './types'

const state = (): RootState => {
  const value: Omit<RootState, 'auth'> = {
    isReady: false,
    platform: Platform.im,
    isOnline: true,
  }

  return value as RootState
}

export default state
