import axios from 'axios'
import { ActionItemResult } from '@/types/action'
import { SocialDistribution } from '@/types/socialDistribution'
import htmlToText from '@/helpers/htmlToText'

export default async function createSocialDistribution({
  text,
  ...payload
}: Partial<Omit<SocialDistribution, 'id' | 'channel'>> &
  Pick<SocialDistribution, 'channel'>): Promise<SocialDistribution> {
  const {
    data: { item },
  } = await axios.post<ActionItemResult<SocialDistribution>>(
    '/api/v1/social-distribution/',
    {
      ...payload,
      text: text && htmlToText(text),
    }
  )

  return item
}
