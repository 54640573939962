import { GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { FixedState } from '../types'

import getActiveSectionId from './getActiveSectionId'

const getters: GetterTree<FixedState, RootState> = {
  getActiveSectionId,
}

export default getters
