import { ActionListPayload, ActionListResult } from '@/types/action'
import { Popblock } from '@/types/popblock'
import axios from 'axios'

export default async function fetchPopblocks({
  page,
  count,
  withItem,
  filter,
  sort,
}: ActionListPayload): Promise<ActionListResult<Popblock>> {
  const { data } = await axios.get('/api/v1/popblock/', {
    params: { page, count, with: withItem, filter, sort },
  })
  return data
}
