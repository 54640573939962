import axios from 'axios'
import { ActionListResult } from '@/types/action'
import { Article } from '@/types/article'

export default async function fetchVideoArticles(
  id: number
): Promise<ActionListResult<Article>> {
  const { data } = await axios.get(`/api/v1/video/article-list/${id}`)
  return data
}
