import { ActionItemResult } from '@/types/action'
import { PlanCompare } from '@/types/planCompare'
import axios from 'axios'

export default async function updateComparePlan({
  id,
  ...params
}: Pick<PlanCompare, 'id'> &
  Partial<Pick<PlanCompare, 'year' | 'month' | 'users' | 'views'>>): Promise<
  ActionItemResult<PlanCompare>
> {
  const { data } = await axios.put(`/api/v1/plan-compare/${id}/`, params)

  return data
}
