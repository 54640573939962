import { ActionListPayload, ActionListResult } from '@/types/action'
import { RecipeIngredient } from '@/types/recipe'
import axios from 'axios'

export default async function fetchRecipeIngredientsNorm({
  cancelToken,
  withItem,
  ...params
}: ActionListPayload): Promise<ActionListResult<RecipeIngredient>> {
  const { data } = await axios.get('/api/v1/recipe-ingredient-norm/', {
    cancelToken,
    params: {
      ...params,
      with: withItem,
    },
  })
  return data
}
