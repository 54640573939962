import { ActionItemResult } from '@/types/action'
import { ResultCreated, Result } from '@/types/test'
import axios from 'axios'

export default async function createTestResult(
  item: ResultCreated
): Promise<ActionItemResult<Result>> {
  const { data } = await axios.post('/api/v1/test-result/', item)
  return data
}
