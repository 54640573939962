import axios from 'axios'
import { ActionItemResult } from '@/types/action'
import { Tag } from '@/types/tag'
import urlCodeFromText from '@/helpers/urlCodeFromText'

export default async function fetchTag({
  withItem,
  id,
  filter,
}: {
  id: number
  withItem?: string
  filter?: string
}): Promise<ActionItemResult<Tag>> {
  const { data } = await axios.get<ActionItemResult<Tag>>(`/api/v1/tag/${id}`, {
    params: { with: withItem, filter },
  })

  const oldCode = data.item.code
  const newCode = data.item.code
    ? urlCodeFromText(data.item.code)
    : urlCodeFromText(data.item.name)

  if (oldCode !== newCode) {
    try {
      await axios.put(`/api/v1/tag/${id}`, {
        code: newCode,
      })

      data.item.code = newCode
    } catch {
      return data
    }
  }

  return data
}
