import { ActionListPayload, ActionListResult } from '@/types/action'
import { Gallery } from '@/types/gallery'
import axios from 'axios'

export default async function fetchGalleries({
  page,
  count,
  cancelToken,
  filter,
  sort,
}: ActionListPayload): Promise<ActionListResult<Gallery>> {
  const { data } = await axios.get('/api/v1/gallery/', {
    cancelToken,
    params: { page, count, filter, sort },
  })

  return data
}
