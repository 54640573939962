import { ModuleTree } from 'vuex'
import { RootState } from '@/store/types'

import sidebar from './sidebar'
import modal from './modal'
import article from './article'
import user from './user'
import auth from './auth'
import fixed from './fixed'
import videoWidget from './videoWidget'
import socialDistribution from './social-distribution'
import podcast from './podcast'
import episode from './episode'

const modules: ModuleTree<RootState> = {
  sidebar,
  modal,
  article,
  user,
  auth,
  fixed,
  videoWidget,
  socialDistribution,
  podcast,
  episode,
}

export default modules
