import { User } from '@/types/user'
import axios, { CancelToken } from 'axios'

export default async function fetchArticleActiveUsers({
  articleId,
  cancelToken,
}: {
  articleId: number
  cancelToken?: CancelToken
}): Promise<User[]> {
  const {
    data: { items },
  } = await axios.get(`/api/v1/article/active-users/${articleId}`, {
    autoLogout: false,
    cancelToken,
  })

  return items
}
