import axios from 'axios'
import { ActionItemResult } from '@/types/action'
import { DistributionNativePost } from '@/types/distribution'

export default async function updateNativePost({
  id,
  ...payload
}: {
  id: number
  publish_date: string
  text: string
  gallery_id: number | null
  video_id: number | null
  images: (number | null)[]
}): Promise<ActionItemResult<DistributionNativePost>> {
  const { data } = await axios.put(`/api/v1/native-post/${id}`, payload)
  return data
}
