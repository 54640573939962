import axios from 'axios'
import { NewEpisode, Episode } from '@/types/episode'
import urlCodeFromText from '@/helpers/urlCodeFromText'

export default async function createEpisode(
  payload: NewEpisode
): Promise<{ item: Episode; meta: { total_count: number } }> {
  const code = payload.code ?? urlCodeFromText(payload.name)

  const { data } = await axios.post<{
    item: Episode
    meta: { total_count: number }
  }>(`/api/v1/podcast-episode/`, { ...payload, code })

  return data
}
